import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import AddPlayListController, { Props } from "./AddPlayListController.web";
import React from "react";
import { Box, Typography, Button, Grid, styled} from "@mui/material";
import Footer from "../../navigationmenu/src/Footer.web";
import AudioPlayerComponent from "../../audiolibrary/src/AudioPlayerComponent.web"
import EditIcon from '@mui/icons-material/Edit';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import { TextField, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Slider from "react-slick";
import ImageCard from "./components/ImageCard.web";
import PlayListModal from "./components/PlayListModal.web";


const MIN_WINDOW_HEIGHT = 900

export default class AddPlayList extends AddPlayListController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            <>
            <div style={webStyles.container} ref={this.boxScrollRef}>
                <Box mb={3}>
                <Grid container spacing={2}>
                    <Grid item md={3}>
                        <Box display={"flex"} flexDirection={"column"} alignItems={"center"} gap={1}>
                            <img src={this.state.mainPlaylistData?.image} style={{width:"273px", height:"273px", borderRadius:"8px" , objectFit:"cover"}} />
                            <Box>
                                <Typography sx={{...webStyles.title, textAlign:"center"}}>Main Playlist</Typography>
                                <Box display={"flex"} justifyContent={"space-around"}>
                                <Box
                                    bgcolor="#312F2D"
                                    padding={1}
                                    borderRadius={1}
                                    data-test-id="edit"
                                >
                                    <EditIcon sx={{ color: "white", }} />
                                </Box>
                                <Box
                                    bgcolor="#FF0807"
                                    padding={1}
                                    borderRadius={1}
                                    data-test-id="play"
                                >
                                    <PlayCircleOutlineIcon sx={{ color: "white", }} />
                                </Box>
                                <Box
                                    bgcolor="#312F2D"
                                    padding={1}
                                    borderRadius={1}
                                    data-test-id="delete"
                                >
                                    <DeleteOutlineIcon sx={{ color: "white", }} />
                                </Box>

                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item md={9}>
                        <Box>
                            <Box display={"flex"} justifyContent={"space-between"}> 
                                <Typography sx={webStyles.title}>Main Playlist</Typography>
                                <Button
                                data-test-id="viewAllPlaylistId"
                                onClick={this.handleNavigatePlaylist}
                                    sx={{
                                    ...buttonStyles,
                                    border: "none",
                                    color: "white",
                                    background: "#FF0807",
                                    "&:hover": {
                                        background: "#FF0807", 
                                    }
                                    }}
                                    variant="contained"
                                    startIcon={<PlaylistAddIcon />}
                                >
                                    View All Playlists
                                </Button>
                            </Box>
                            <Box height={"400px"} p={1} color={"white"} bgcolor={"#44403C"} overflow="scroll" sx={{overflowX:"auto"}}>
                            {this.state.mainPlaylistInfo.map((data, index) => (
                                <Box
                                    data-test-id="playlist-item"
                                    key={index}
                                    display={"flex"}
                                    alignItems="center"
                                    justifyContent={"space-between"}
                                    borderBottom="1px solid #F5F5F5"
                                    padding={2}
                                    sx={{
                                        backgroundColor: index === this.state.selectedPlaylist ? "black" : "",
                                        cursor: "pointer",
                                      }}
                                    onClick={() => this.handlePlaylistRowClick(index)}
                                >
                                    <Box display="flex" flex={4} gap={2}>
                                    <img
                                        src={data.cover_image}
                                        alt={data.name}
                                        style={{ width: "40px", height: "40px" }}
                                    />
                                    <Box>
                                        <Typography
                                        fontFamily={"Nexa-Heavy"}
                                        textOverflow={"ellipsis"}
                                        whiteSpace={"nowrap"}
                                        overflow={"hidden"}
                                        fontWeight={900}
                                        fontSize="16px"
                                        >
                                        {data.name}
                                        </Typography>
                                        <Typography
                                        textOverflow={"ellipsis"}
                                        whiteSpace={"wrap"}
                                        overflow={"hidden"}
                                        fontFamily={"Nexa-Regular"}
                                        fontWeight={400}
                                        fontSize="14px"
                                        >
                                        {data.description}
                                        </Typography>
                                    </Box>
                                    </Box>

                                    <Typography
                                    flex={1}
                                    fontFamily={"Nexa-Regular"}
                                    fontWeight={400}
                                    fontSize="14px"
                                    >
                                    {data.episode_time}
                                    </Typography>

                                    <Box
                                    bgcolor="#FF0807"
                                    borderRadius={1}
                                    data-test-id="deleteBtn"
                                    onClick={() => this.handleDeleteSong(data)}
                                    >
                                        {
                                           index === this.state.selectedPlaylist && this.state.isRemoveSongLoading ? 
                                            <Typography
                                                color={"white"}
                                                padding={1}
                                                fontFamily={"Nexa-Regular"}
                                                fontWeight={"bold"}
                                                fontSize="14px"
                                                >
                                                Deleting...
                                            </Typography>
                                            :
                                            <DeleteOutlineIcon sx={{ color: "white" }} />
                                        }
                                    </Box>
                                </Box>
                            ))}                                
                            </Box>
                        </Box>

                        <Box mt={5}>
                            <Box><Typography sx={webStyles.title}>Search</Typography></Box>
                            <Box mt={1}>       
                                <TextField
                                    InputProps={{
                                        startAdornment: (
                                        <InputAdornment position="start">
                                             <SearchIcon sx={{ color: "white", fontSize: "20px" }} />
                                        </InputAdornment>
                                        ),
                                    }}
                                    fullWidth 
                                    placeholder="Search a podcast"
                                    variant="outlined"
                                    sx={{
                                    "& .MuiOutlinedInput-root": {
                                        fontFamily: "Nexa-Heavy",
                                        borderRadius: "98px", 
                                        color:"white",
                                        border:"1px solid white",
                                    },
                                    "& .MuiOutlinedInput-input": {
                                        padding: "8px 2px", 
                                    },
                                    }}
                                    data-test-id = "podcast_search"
                                    value={this.state.searchValue}
                                    onChange={this.handleSearchInput}
                                    onKeyDown={this.handleSearchKeyDown}
                                />
                            </Box>
                            {/* card carousel */}
                            {
                                this.state.isPodcastLoading ? (
                                    <Box mt={2} width={"97%"} textAlign={"center"}>
                                    <Typography sx={webStyles.title}>Loading...</Typography>
                                    </Box>
                                ) : (
                                    <>
                                    {this.state.podcastsListInfo.length > 0 ? (
                                        <Box mt={2} width={"97%"}>
                                        <StyledSearchSlider {...this.getSearchSlickSettings()}>
                                            {this.state.podcastsListInfo.map((data, index) => (
                                            <ImageCard
                                                data-test-id="carousel_card"
                                                key={data.id}
                                                imageUrl={data.cover_image}
                                                songName={data.title}
                                                artist={data.subtitle}
                                                following={data?.following}
                                                onClick={() => this.handleImageCardClick(data)}
                                                followingClick = {() => this.handleFollowingClick(data)}
                                                unFollowingClick = {() => this.handleUnFollowingClick(data)}
                                            />
                                            ))}
                                        </StyledSearchSlider>
                                        </Box>
                                    ) : (
                                        <Box mt={2} width={"97%"} textAlign={"center"}>
                                        <Typography sx={webStyles.title}>No podcasts available</Typography>
                                        </Box>
                                    )}
                                    </>
                                )
                            }
                        </Box>
                    </Grid>
                </Grid>
                </Box>
                <Footer navigation={this.props.navigation} id={"1"} />
            </div>

            {
                this.state.openPlaylistModal &&
                    <PlayListModal 
                        open={this.state.openPlaylistModal} 
                        onClose={this.handlePlaylistClose} 
                        episodeDataLoading = {this.state.isEpisodeLoading} 
                        episodeData={this.state.episodesData}
                        selectedData={this.state.selectedSearchPodcast}  
                        handleAddEpisode={(id) => this.handleEpisodeAdd(id)}
                        addEpisodeIsLoading = {this.state.isAddEpisodeLoading}
                        addEpisodeId = {this.state.addEpisodeId}
                        handleDone = {this.hanldeEpisodeDone}
                    />
            }                   
            </>
        )
    }
}

const webStyles = {
    container: {
        padding:"20px 50px",
        overflowY: "auto",
        maxHeight: "100%",
        title: {
            fontWeight: 900,
            fontSize: "30px",
            lineHeight: "40px",
            letter: "-0.5%",
            color: "#fff",
            fontFamily: "Nexa-Heavy"
        },
    } as const,
    title: {
        fontWeight: 900,
        fontSize: "30px",
        lineHeight: "40px",
        letter: "-0.5%",
        color: "#fff",
        fontFamily: "Nexa-Heavy"
    },
}

const buttonStyles = {
    borderRadius: "8px",
    fontFamily: "Nexa-Heavy",
    fontSize: "0.875rem",
    textTransform: "capitalize",
    height: "34px",
};

const StyledSearchSlider = styled(Slider)`
.slick-slide {
    width: 260px !important;
}
.slick-slider {
  display: block;
  position: relative;
  overflow: hidden; 
}
  .slick-list {
  display: flex;
  flex-wrap: nowrap; /* Prevents items from wrapping */
  overflow: hidden; /* Hides overflow */
}

.slick-track {
  display: flex;
  flex-wrap: nowrap; /* Prevents items from wrapping */
  transition: transform 0.5s ease-in-out; /* Smooth scrolling */
}
`;