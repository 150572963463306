// Customizable Area Start
import React from "react";
import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined';
import {
    addBtn,
    heart,
    prev,
    next,
    nextsec,
    volumeIcon,
    size,
    backsec,
    comments,
    shares,
    like,
    pauseIcon,
    playBtn,
    unfold,
    muteIcon,
    likedBtn,
    addedToPlayList
} from "./assets";
import { ResolveCondition } from "../../utilities/src/CustomHelpers";
import AudioPlayerComponentController, {
    Props,
} from "./AudioPlayerComponentController";
import { Box, Dialog, Typography, TextField, Button   } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Checkbox from "@mui/material/Checkbox";

export default class AudioPlayerComponent extends AudioPlayerComponentController {
    constructor(props: Props) {
        super(props);
    }
    render() {
        const {
            speed,
            isPlaying,
            currentTrack,
            audioTracks,
            currentTime,
            duration
        } = this.state;

        const WIDE_SCREENS = window.innerWidth > 1500

        return (
            <div
                style={{
                    ...styles.playerWrapper,
                    ...(this.props.activeScreen && { justifyContent: "center" }),
                }}
            >

                <div style={styles.podcastWrapper} >
                    <img src={audioTracks.cover} width="76px" height="74px" alt="Cover" />
                    <div style={styles.podcastDetails}>
                        <div style={{ fontSize: "16px", color: "#F8FAFC",fontFamily: "Nexa-Heavy", fontWeight: 900}}> {audioTracks.title} #{audioTracks.episode_number}</div>
                        <div style={{ fontSize: "16px", color: "#F8FAFC", fontFamily: "Nexa-Heavy", fontWeight: 100,width: "117px", height: "auto" }}>{audioTracks.artist}</div>
                        {/* {!WIDE_SCREENS &&
                            <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
                                <img src={heart} />
                                <img src={addBtn} />
                            </div>
                        } */}
                    </div>
                    {/* {WIDE_SCREENS && */}
                        <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                            {
                                audioTracks.saved ? 
                                    <img src={likedBtn} data-test-id = "saved" onClick={ () => this.handleUnsaveEpisode(audioTracks.saved_id) } />
                                :   <img src={heart} data-test-id = "unSaved" onClick={ () => this.handleSaveEpisode(audioTracks.episodeId)} />

                            }
                            {
                                audioTracks.song_added ?
                                    <img src={addedToPlayList} data-test-id = "song_remove" onClick={() => this.handleRemoveFromPlayList(audioTracks.episodeId)} />    
                                :   <img src={addBtn} data-test-id = "song_add" onClick={() => this.handleAddToPlayList(audioTracks.episodeId)} />    

                            }
                        </div>
                    {/* // } */}
                </div>
                <div style={styles.playerIcons} >
                    <div style={{ display: "flex", gap: 15, alignItems: "center" }} >
                        <img src={backsec} alt="Rewind 10 seconds" data-test-id="rewind10Seconds" onClick={this.rewind10Seconds} style={{ cursor: 'pointer' }} />
                        <img data-test-id="prevClick" src={prev} style={{ cursor: 'pointer' }} onClick={() => { this.handleTrackChange(this.state.arroundEpisodes.previous_episode) }
                        } />
                        {isPlaying ?
                            <img src={pauseIcon} style={{cursor: 'pointer'}} data-test-id="pauseIcn" onClick={this.handlePlayPause} /> :
                            <img src={playBtn} style={{cursor: 'pointer'}} data-test-id="playIcn" onClick={this.handlePlayPause} width="44px" height="43px" />}
                        <img src={next} data-test-id="nextIcn" style={{ cursor: 'pointer' }} onClick={() => { this.handleTrackChange(this.state.arroundEpisodes.next_episode) }} />
                        <img src={nextsec} alt="Skip 10 seconds" data-test-id="skip10Seconds" onClick={this.skip10Seconds} style={{ cursor: 'pointer' }} />
                        <select
                            style={styles.select}
                            value={speed}
                            data-test-id="speed"
                            onChange={(e) => { this.handleSpeedChange(e) }}>
                            <option style={{ fontWeight: 900 }} value="1">1x</option>
                            <option style={{ fontWeight: 900 }} value="1.5">1.5x</option>
                            <option style={{ fontWeight: 900 }} value="2">2.x</option>
                        </select>
                    </div>
                    <div style={{ display: "flex", alignItems: "center", gap: 15 }}>
                        <div data-test-id="currentTimeDisplay" style={styles.time}>{this.formatTime(currentTime)}</div>
                        <div onClick={(e) => { this.handleProgressClick(e) }} data-test-id="progressClick" style={{ width: "35vw", height: "4px", backgroundColor: "grey", borderRadius: "2px", overflow: "hidden" }}>
                            <div style={{ width: `${(currentTime / duration) * 100}%`, height: '100%', backgroundColor: '#FF0807' }}></div>
                        </div>
                        <div style={styles.time}>{this.formatTime(duration)}</div>
                    </div>
                    {/* {!WIDE_SCREENS &&
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: 60 }}>
                            <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                                <img src={like} />
                                <div style={{ ...styles.count, color: "#FF0807" }}>{audioTracks.liked_count}</div>
                            </div>
                            <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                                <img src={comments} />
                                <div style={styles.count}>994</div>
                            </div>
                            <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                                <img src={shares} />
                                <div style={styles.count}>1.2K</div>
                            </div>
                        </div>
                    } */}
                </div>
                {/* {WIDE_SCREENS && */}
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: 30 }}>
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 2 }}>
                            {
                                audioTracks.liked ? (
                                    <img src={like} alt="Liked"
                                        data-test-id="handleLikeButton"
                                        style={{ height: "30px", width: "30px" }}
                                        onClick={() => {
                                            this.handleLike(audioTracks.liked_id, audioTracks.liked);
                                        }}
                                    />
                                ) : (
                                    <ThumbUpAltOutlinedIcon
                                        onClick={() => {
                                            this.handleLike(audioTracks.episodeId, audioTracks.liked);
                                        }}
                                        style={{ color: 'white', fontSize: 30, cursor: "pointer" }}
                                        data-test-id="dislikeButton"
                                    />
                                )}

                            <div style={{ ...styles.count, color: audioTracks.liked ? "#FF0807" : "white" }}>{audioTracks.liked_count}</div>
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 2 }}>
                            <img src={comments} />
                            <div style={styles.count}>994</div>
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 2 }}>
                            <img src={shares} onClick={this.handleShareClick} />
                            <div style={styles.count}>1.2K</div>
                        </div>
                    </div>
                 {/* } */}

                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", color: "#fff" }} >
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: 10 }}>
                    <img 
                        src={this.state.isMuted ? muteIcon : volumeIcon} 
                        onClick={this.handleMuteClick} 
                        alt="Volume Icon" 
                        style={{ cursor: "pointer" }} 
                    />
                        {/* <img src={volumeIcon} /> */}
                        <div style={{ width: "100px", height: "4px", backgroundColor: "grey", borderRadius: "2px", overflow: "hidden" }} data-test-id="volumeClick" onClick={(e) => { this.handleVolumeClick(e) }}>
                            <div style={{ width: `${this.state.volume * 100}%`, height: "100%", backgroundColor: this.state.isMuted ? "red": "#FFF" }}></div>
                        </div>
                        {
                            this.props.activeScreen === "AudioPlayerFullScreen" ? 
                            <img src={size} style={{ cursor: "pointer" }} data-test-id="fullscreen"
                            onClick={this.props.exitFullScreen}
                            />
                            :
                            <img src={unfold} style={{ cursor: "pointer" }} data-test-id="fullscreen"
                                onClick={this.props.handleFullScreenClick}
                            />
                        }
                    </div>
                </div>
                <audio
                    ref={this.audioRef}
                    onTimeUpdate={this.handleTimeUpdate}
                    onLoadedMetadata={this.handleLoadedMetadata}
                    src={this.state.audioTracks.src}
                />

                {
                    <Dialog
                        open={this.state.showShareModal}
                        onClose={() => {}}
                        slotProps={{
                        backdrop: {
                            sx: {
                            backdropFilter: 'blur(5px)', // Apply blur effect
                            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Optional semi-transparent background
                            },
                        },
                        }}
                        sx={{
                        "& .MuiDialog-paper": {
                            maxWidth: "1150px",
                            width: "1150px",
                            height: "430px",
                            background: "#44403C !important",
                            borderRadius:"8px",
                            borderBottomRightRadius:"32px"
                        },
                        }}
                        data-test-id="shareModal"
                    >
                        <Box padding={3}>
                            <Box display={"flex"} justifyContent={"flex-end"} onClick={this.closeShareModal}>
                                <CloseIcon sx={{color:"white"}} />
                            </Box>
                            <Box display={"flex"}>
                                <img src={shares} style={{width:"22px"}} />
                                <Typography sx={{ fontSize: "24px", color: "#F8FAFC",fontFamily: "Nexa-Heavy", fontWeight: 900}} color={"white"}>
                                    Share Episode
                                </Typography>
                            </Box>
                            <Box mt={5}>
                            <Box display={"flex"} gap={2} alignItems={"flex-end"}>
                                <Box>
                                    <Typography sx={{ fontSize: "14px", color: "#F8FAFC",fontFamily: "Nexa-Heavy", fontWeight: 900, marginBottom:"8px"}}>Share</Typography>
                                    <TextField
                                        name="shareValue" 
                                        onChange={this.handleInputChange} 
                                        InputProps={{
                                        style: {
                                            color: "white",
                                            backgroundColor: "black",
                                            borderRadius: "8px",
                                            height: "56px", // Explicit height
                                        },
                                        }}
                                        value={this.state.shareValue}
                                        sx={{
                                        width: "707px",
                                        "& .MuiOutlinedInput-root": {
                                            fontFamily: "Nexa-Heavy",
                                            fontWeight: 900,
                                            fontSize: "14px",
                                            height: "56px", // Match height
                                            "& fieldset": {
                                            borderColor: "white",
                                            borderRadius: "8px",
                                            },
                                            "&:hover fieldset, &.Mui-focused fieldset": {
                                            borderColor: "white",
                                            },
                                        },
                                        }}
                                    />
                                </Box>
                                {this.state.copied.share ? 
                                    <Button
                                    variant="outlined"
                                    sx={{
                                        bgcolor: "#030303", 
                                        color: "white", 
                                        fontFamily: "Nexa-Heavy",
                                        height: "56px", 
                                        borderRadius: "8px", 
                                        borderColor: "#030303", 
                                        "&:hover": {
                                        bgcolor: "#030303", 
                                        color: "white", 
                                        borderColor: "#030303", 
                                        },
                                    }}
                                    size="large"
                                    >
                                    Copied
                                </Button>
                                :
                                <Button
                                    data-test-id="shareCopyBtn"
                                    onClick={() => this.handleCopy(this.state.shareValue, "share")}
                                    variant="outlined"
                                    sx={{
                                        bgcolor: "#FF0807", 
                                        color: "white", 
                                        fontFamily: "Nexa-Heavy",
                                        height: "56px", 
                                        borderRadius: "8px", 
                                        borderColor: "#FF0807", 
                                        "&:hover": {
                                        bgcolor: "#FF0807", 
                                        color: "white", 
                                        borderColor: "#FF0807", 
                                        },
                                    }}
                                    size="large"
                                    startIcon={<ContentCopyIcon />}>
                                    Copy
                                </Button>}
                                <Checkbox
                                    sx={{
                                        color: "#FFFFFF",
                                        fontSize:"38px",
                                        '&.Mui-checked': {
                                            color: "#FF0807",
                                        },
                                        "& .MuiSvgIcon-root": {
                                            fontSize: 38,
                                        },
                                    }}  
                                />
                                <Box display={"flex"} alignItems={"center"} gap={2}> 
                                <Typography sx={{ fontSize: "14px", color: "#F8FAFC",fontFamily: "Nexa-Heavy", fontWeight: 900, marginBottom:"8px"}}>Start At</Typography>
                                <TextField
                                    name="episodeTiming"
                                    value={this.state.episodeTiming}
                                    onChange={this.handleInputChange} 
                                    InputProps={{
                                    style: {
                                        color: "white",
                                        backgroundColor: "black",
                                        borderRadius: "8px",
                                        height: "56px", // Explicit height
                                    },
                                    }}
                                    sx={{
                                    width: "58px",
                                    "& .MuiOutlinedInput-root": {
                                        fontFamily: "Nexa-Heavy",
                                        fontWeight: 900,
                                        fontSize: "14px",
                                        height: "56px", // Match height
                                        "& fieldset": {
                                        borderColor: "white",
                                        borderRadius: "8px",
                                        },
                                        "&:hover fieldset, &.Mui-focused fieldset": {
                                        borderColor: "white",
                                        },
                                    },
                                    }}
                                />
                                </Box>
                            </Box>
                            <Box mt={3} display={"flex"} gap={2} alignItems={"flex-end"}>
                                <Box>
                                    <Typography sx={{ fontSize: "14px", color: "#F8FAFC",fontFamily: "Nexa-Heavy", fontWeight: 900, marginBottom:"8px"}}>Embed</Typography>
                                    <TextField
                                        name="embedValue" 
                                        onChange={this.handleInputChange} 
                                        value={this.state.embedValue}
                                        InputProps={{
                                        style: {
                                            color: "white",
                                            backgroundColor: "black",
                                            borderRadius: "8px",
                                        },
                                        }}
                                        sx={{
                                        width: "707px",
                                        "& .MuiOutlinedInput-root": {
                                            fontFamily: "Nexa-Heavy",
                                            fontWeight: 900,
                                            fontSize: "14px",
                                            "& fieldset": {
                                            borderColor: "white",
                                            borderRadius: "8px",
                                            },
                                            "&:hover fieldset, &.Mui-focused fieldset": {
                                            borderColor: "white",
                                            },
                                        },
                                        }}
                                    />
                                </Box>
                                { this.state.copied.embed ? 
                                    <Button
                                        variant="outlined"
                                        sx={{
                                            bgcolor: "#030303", 
                                            color: "white", 
                                            fontFamily: "Nexa-Heavy",
                                            height: "56px", 
                                            borderRadius: "8px", 
                                            borderColor: "#030303", 
                                            "&:hover": {
                                            bgcolor: "#030303", 
                                            color: "white", 
                                            borderColor: "#030303", 
                                            },
                                        }}
                                        size="large"
                                        >
                                        Copied
                                    </Button>
                                    :
                                    <Button
                                        data-test-id="embedCopyBtn"
                                        onClick={() => this.handleCopy(this.state.embedValue, "embed")}
                                        variant="outlined"
                                        sx={{
                                            bgcolor: "#FF0807", 
                                            color: "white", 
                                            fontFamily: "Nexa-Heavy",
                                            height: "56px", 
                                            borderRadius: "8px", 
                                            borderColor: "#FF0807", 
                                            "&:hover": {
                                            bgcolor: "#FF0807", 
                                            color: "white", 
                                            borderColor: "#FF0807", 
                                            },
                                        }}
                                        size="large"
                                        startIcon={<ContentCopyIcon />}
                                        >
                                        Copy
                                    </Button>
                                }
                            </Box>
                            </Box>
                        </Box>

                    </Dialog>
                }
            </div>
        );
    }
}

const styles = {    
    container: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        height: '106px',
    },
    mainContent: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flexGrow: 1,
    },
    playerWrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "8px",
        width: "95%",
    },
    podcastWrapper: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: 15
    },
    podcastDetails: {
        display: "flex",
        flexDirection: "column" as const,
        color: "white"
    },
    playerIcons: {
        display: "flex",
        flexDirection: "column" as const,
        justifyContent: "center" as const,
        alignItems: "center" as const,
    },
    select: {
        backgroundColor: 'black',
        color: 'white',
        border: "none",
        fontWeight: 900,
        borderRadius: 8,
        fontSize: "16px",
        padding: "8px 0"
    },
    time: {
        color: "#F8FAFC",
        fontWeight: 700
    },
    count: {
        fontWeight: 900,
        fontSize: 14,
        color: "#fff",
    }
};
// Customizable Area End
