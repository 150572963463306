export const image1 = require("../assets/image1.png").default;
export const image2 = require("../assets/image2.png").default;
export const image3 = require("../assets/image3.png").default;
export const prev = require("../assets/prev.svg").default
export const next = require("../assets/next.svg").default
export const sponsored1 = require("../assets/sponsored1.png").default
export const suggested1 = require("../assets/suggested1.jpg").default
export const suggested2 = require("../assets/suggested2.jpg").default
export const suggested3 = require("../assets/suggested3.jpg").default
export const heart = require("../assets/heart.svg").default
export const addBtn = require("../assets/addBtn.svg").default
export const selectedBtn = require("../assets/selectedBtn.svg").default
export const addedBtn = require("../assets/addedBtn.svg").default
export const buildBackImg = require("../assets/backgroundImg.png").default
export const podcastImg = require("../assets/podcastImg.jpg").default
export const handImg = require("../assets/handImg.jpg").default
export const womenImg = require("../assets/womenImg.jpg").default
export const mediaCardImg = require("../assets/mediabg.png").default
export const leftArrow = require("../assets/leftArrow.png").default
export const rightArrow = require("../assets/rightArrow.png").default
export const uploadRedIcon = require("../assets/uploadRedIcon.png").default