import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import React, { createRef } from 'react'
import { getStorageData } from "framework/src/Utilities";
import { micImg } from "./assets";
import { prev, rightArrow } from "../../dashboard/src/assets";

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    handleCommonPath:(path:string) => void;
}

interface ArrowProps {
    onClick: () => void;
  }

interface SS {
    id: any;
}

interface AddedAttributes {
    song_added: boolean;
  }

  interface IPodcastEpisode {
    song_id: string | number;
    name: string;
    description: string;
    endTime: string;
    songAdded: boolean;
    coverImage: string;
  }

  interface IplaylistInfo {
    title: string;
    image: string;
  }

  interface ISelectedPlaylist {
    id: string;
    cover_image: string ; 
    name: string;
    description: string;
    episode_time: string | null; 
  }

  interface IMainPlaylist {
    id: string;
    cover_image: string ; 
    name: string;
    description: string;
    episode_time: string | null; 
    songAdded: boolean;
  }

  interface IPodcastAttributes {
    id: number,
    title: string | null;
    subtitle: string | null;
    cover_image: string | null;
    following?: boolean;
    follow_id? : string;
  }

  interface ISelectedPodcast {
    cover: string | null;
    name: string | null;
    description: string | null;
    podcastId: number;
  }

  interface IEpisodeList {
    id: string;
    cover: string;
    name: string;
    description: string;
    episode_time: string | null; 
    songAdded: boolean;
  }
  
interface S {
    mainPlaylistInfo: IMainPlaylist[];
    selectedPlaylist: number | null;
    playlistInfo: IplaylistInfo | null;
    selectedPlaylistInfo: ISelectedPlaylist[];
    addSongId: null | number;
    podcastsListDatas: IPodcastAttributes[];
    isPodcastListLoading: boolean;
    searchInputValue: string;
    openPlaylistModal: boolean;
    selectedSearchPodcast: ISelectedPodcast;
    isEpisodeLoading: boolean;
    episodesList: IEpisodeList[];
    isAddToPlaylistLoading: boolean;
    addEpisodeId: string;
    isDeleteLoading: boolean;
}

export default class NewPlayListEditController extends BlockComponent<Props, S, SS> {
    getMainPlaylistApiCallId = ""
    getAddSongsApiCallId = ""
    getShowPlaylistApiCallId = ""
    podcastListApiId = ""
    searchInputApiCallId = ""
    searchCarouselCardApiCallId = ""
    addOneEpisodeApiCallId = ""
    getDeletePlaylistAPICallId = ""
    unFollowPodcastCallId: string = ""
    followPodcastCallId: string = ""
    scrollBox: any = createRef();

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.state = {
            mainPlaylistInfo: [],
            selectedPlaylist: null,
            playlistInfo: {
              title:"",
              image:""
            },
            selectedPlaylistInfo: [],
            addSongId: null,
            podcastsListDatas: [],
            isPodcastListLoading: false,
            searchInputValue: "",
            openPlaylistModal: false,
            selectedSearchPodcast: {
              cover: "",
              name: "",
              description:"",
              podcastId: 0,
            },
            isEpisodeLoading: false,
            episodesList: [],
            isAddToPlaylistLoading: false,
            addEpisodeId: "",
            isDeleteLoading: false
        };
    
        this.subScribedMessages = [
          getName(MessageEnum.RestAPIResponceMessage),
        ];
        
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
    runEngine.debugLog("Message Received", message);


    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );

        if(this.getMainPlaylistApiCallId === apiRequestCallId) {
          this.handleMainPlaylistResponse(from, message)
        }

        if(this.getAddSongsApiCallId === apiRequestCallId) {
          this.handleAddsongResponse(from, message)
        }

        if(this.getShowPlaylistApiCallId === apiRequestCallId) {
          this.handleShowPlaylistResponse(from, message)
        }

        if(this.podcastListApiId === apiRequestCallId) {
          this.podcastListsResponse(from, message);
        }


        if(this.searchInputApiCallId === apiRequestCallId) {
          this.handleSearchResponseData(from, message);
        }

        if(this.searchCarouselCardApiCallId === apiRequestCallId) {
          this.handleSearchEpisodesListResponse(from, message)
        }

        if(this.addOneEpisodeApiCallId === apiRequestCallId) {
          this.handleEpisodesListResponse(from, message);
        }

        if(this.getDeletePlaylistAPICallId === apiRequestCallId) {
          this.handleSongDeleteResponse(from, message)
        }

        if(this.unFollowPodcastCallId === apiRequestCallId) {
          this.handleUnfolowResponse(from, message);
        }
  
        if(this.followPodcastCallId === apiRequestCallId) {
          this.handleFollowResponse(from, message)
        }
    }

    }

    async componentDidMount(): Promise<void> {

        this.getMainPlaylist();
        this.showPlaylist();
        this.showPodcastsList();
        setTimeout(() => {
          if (this.scrollBox.current) {
            this.scrollBox.current.scrollTo({ top: 0, behavior: "smooth" });
          }
        }, 0);
    }

    handlePlaylistRowClick = (index: number) => {
    this.setState({ selectedPlaylist: index });
    }

    getSearchSlickSettings(){
        const innerWidth = typeof window !== 'undefined' ? window.innerWidth : 0;
    
        return(
           {
            dots: false,
            infinite: true,
            speed: 800,
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 0,
            nextArrow: React.createElement(this.nextSearchArrow, { onClick: () => {}, innerWidth }),
            // prevArrow: React.createElement(this.prevSearchArrow, { onClick: () => {}, innerWidth }),
            responsive: [
              {
                breakpoint: 1700, 
                settings: {
                  slidesToShow: 1, 
                  slidesToScroll: 1
                }
              },
              {
                breakpoint: 1920,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                }
              },
              {
                breakpoint: 1440,
                settings: {
                  slidesToScroll: 1,
                  slidesToShow: 1,
                }
              },
            ]
           }
        )
    }
    
    prevSearchArrow = (props: ArrowProps & { innerWidth: number }) => {
    const { onClick, innerWidth } = props

    return(
        <img
        src={prev} 
        onClick={onClick} 
        style={{
            position: 'absolute',
            left: innerWidth > 1440 ? "-21.5%" : "-0.5%",
            top: innerWidth > 1440 ? "55%" : "40%",
            transform: 'translateY(-50%)',
            cursor: 'pointer',
            zIndex: 1,
        }} 
        alt="Previous Arrow"
        />
    )
    }
    
    nextSearchArrow = (props: ArrowProps & { innerWidth: number }) => {
    const { onClick, innerWidth } = props

    return(
      <div onClick={onClick} style={{
        borderRadius: "50%",
        height: "50px", width: "50px",
        position: 'absolute',
        background: "#FF0807",
        top: '50%',
        right: "-2%",
        transform: 'translateY(-50%)',
        zIndex: 1,
        cursor: 'pointer',
      }}>
        <img
          src={rightArrow}
          style={{
            width: "100%",
            height: "100%"
          }}
          alt="Arrow"
        />
      </div>
    )
    }

    getMainPlaylist = async() => {

        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
      
        this.getMainPlaylistApiCallId = requestMessage?.messageId

        const mainPlaylistId  = await getStorageData('main_playlist_id')  

        const token = await getStorageData('token')
    
        let cleanedToken = token.slice(1, -1);
        
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.songListApiEndPoint}/${mainPlaylistId}`
        );
      
        requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify({
            "Content-Type": configJSON.validationApiContentType,
            token: cleanedToken
        })
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
          );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleAddBtnClick = async(song_id: string | number, index: number) => {

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );


      const token = await getStorageData('token')
      
      let cleanedToken = token.slice(1, -1);

      this.getAddSongsApiCallId = requestMessage?.messageId

      const getPlaylistId  = await getStorageData('playlist_id')      

      let formdata = new FormData();
      formdata.append("id", getPlaylistId);
      formdata.append("song_id", song_id.toString())
    

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.addSong}`
      );
        
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify({
            token: cleanedToken
        })
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiPostMethod
    );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formdata
      );

      this.setState({addSongId: index})
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleMainPlaylistResponse = async(from: string, message: Message) => {

      let apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if(apiResponse?.playlist?.data?.id) {
        const filteredPlaylist: IMainPlaylist[] = 
        apiResponse?.playlist?.data?.attributes?.songs?.data?.map((item: any) => ({
          id: item.id,
          cover_image: item.attributes.cover || null,
          name: item.attributes.name || '',
          description: item.attributes.description || '',
          episode_time: item.attributes.episode_time || null,
          songAdded: item.attributes.added.song_added
        })) || [];

        this.setState({mainPlaylistInfo: filteredPlaylist})
        this.getMainPlaylistApiCallId = ""
      } 

    }

    handleAddsongResponse = async(from: string, message: Message) => {
      let apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if(apiResponse?.message === "Song added successfully") {
        this.setState({addSongId: null})
        this.getMainPlaylist();
        this.showPlaylist();
      }
      else {
        this.setState({addSongId: null})
        this.showAlert("Error", "Song Not Added")
      }
    }

    showPlaylist = async() => {
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
    
      this.getShowPlaylistApiCallId = requestMessage?.messageId


      const getPlaylistId  = await getStorageData('playlist_id')     

      let playlistId = getPlaylistId;

      const token = await getStorageData('token')
  
      let cleanedToken = token.slice(1, -1);

      requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.songListApiEndPoint}/${playlistId}`
      );
    
      requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
          "Content-Type": configJSON.validationApiContentType,
          token: cleanedToken
      })
      );

      requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.validationApiMethodType
        );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleShowPlaylistResponse = async(from: string, message: Message) => {
      let apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

        if(apiResponse.playlist.data.id) {

          this.setState({
            playlistInfo: {
              title: apiResponse?.playlist?.data?.attributes?.title || '',
              image: apiResponse?.playlist?.data?.attributes?.image || '',
            },
          });
          
          const filteredPlaylist: ISelectedPlaylist[] = 
            apiResponse?.playlist?.data?.attributes?.songs?.data?.map((item: any) => ({
              id: item.id,
              cover_image: item.attributes.cover || null,
              name: item.attributes.name || '',
              description: item.attributes.description || '',
              episode_time: item.attributes.episode_time || null,
            })) || [];
          
          this.setState({ selectedPlaylistInfo: filteredPlaylist })
        }
    }

    handleAllPlaylist = () => {
      this.props.handleCommonPath("Playlist")
    }

    showPodcastsList  = async() => {
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.setState({isPodcastListLoading: true});

      const getToken = await getStorageData('token')
  
      let token = getToken.slice(1, -1);

      this.podcastListApiId = requestMessage?.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.listPodCastsEndPoint}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify({
            "Content-Type": configJSON.validationApiContentType,
            token: token
        })
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    
    podcastListsResponse = async(from: string, message: Message) => {
      let response = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if(response?.data?.length !== 0) {

        const listData = response?.data?.map((podcast:any) => ({
          id: podcast.id,
          title: podcast.attributes.name,
          subtitle: `${podcast.attributes.hosts?.[0]?.first_name ?? ''} ${podcast.attributes.hosts?.[0]?.last_name ?? ''}`.trim(),
          cover_image: podcast.attributes.cover_image,
          following: podcast.attributes.follower.following,
          follow_id: podcast.attributes?.follower?.follow_id,
        }));

        this.setState({isPodcastListLoading: false});
  
        this.setState({podcastsListDatas: listData})
      }
      else {
        this.setState({isPodcastListLoading: false});
        this.setState({podcastsListDatas: []})
      }
    }

    handleSearchPodcast = (event: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({searchInputValue: event.target.value})
    }

    handlePodcastKeyDown = async(event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter") {
        
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );

        this.searchInputApiCallId = requestMessage?.messageId;
    
        const token = await getStorageData('token')
    
        let cleanedToken = token.slice(1, -1);
    
        this.setState({isPodcastListLoading: true});
        
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify({
              "Content-Type": configJSON.validationApiContentType,
              token: cleanedToken
          })
        );

        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.apiGetMethod
        );

        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.globalSearchEndPoint}?query=${this.state.searchInputValue}`
        );
        
        runEngine.sendMessage(requestMessage.id, requestMessage);
      }
    }

    handleSearchResponseData = async(from: string, message: Message) => {
      let apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
  
      if(apiResponse?.podcasts?.data.length > 0){
        this.setState({isPodcastListLoading: false});
  
        const responseData = apiResponse?.podcasts?.data?.map((podcast:any) => ({
          id: podcast.id,
          title: podcast.attributes.name,
          subtitle: `${podcast.attributes.hosts?.[0]?.first_name ?? ''} ${podcast.attributes.hosts?.[0]?.last_name ?? ''}`.trim(),
          cover_image: podcast.attributes.cover_image,
          following: podcast.attributes.follower.following,
          follow_id: podcast.attributes?.follower?.follow_id,
        }));
    
        this.setState({podcastsListDatas: responseData})
      }
      else {
        this.setState({isPodcastListLoading: false});
        this.setState({podcastsListDatas: []})
      }
    }

    handleCarouselCardClick = async(data:IPodcastAttributes) => {
      this.setState({openPlaylistModal: true});
      this.setState({
        selectedSearchPodcast: {
          cover: data.cover_image,
          name: data.title,
          description: data.subtitle,
          podcastId: data.id
        }
      });

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      const token = await getStorageData('token')
    
        let getToken = token.slice(1, -1);
    
        this.searchCarouselCardApiCallId = requestMessage?.messageId;
  
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify({
              "Content-Type": configJSON.validationApiContentType,
              token: getToken
          })
        );

        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.listPodCastsEndPoint}/${data?.id}`
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.apiGetMethod
        );

        this.setState({isEpisodeLoading: true});
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleSearchEpisodesListResponse = async(from: string, message: Message) => {
      let episodeResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      
      if(episodeResponse.episodes.data.length > 0) {
        const extractedData = episodeResponse.episodes.data.map((item:any) => {
          const { id, attributes } = item;
          const { cover, name, description, episode_time, added } = attributes;

          return {
            cover,
            id,
            description,
            name,
            songAdded: added?.song_added,
            episode_time,
          };
        });
        this.setState({isEpisodeLoading: false});
        this.setState({episodesList: extractedData})
      }
      else {
        this.setState({isEpisodeLoading: false});
        this.setState({episodesList: []})
      }
    }

    handleEpisodesAdd = async(id: string) => {
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      const token = await getStorageData('token')
      
      let getToken = token.slice(1, -1);
      
      const main_playlist_id = await getStorageData('main_playlist_id')
      
      this.addOneEpisodeApiCallId = requestMessage?.messageId
      
        let formdata = new FormData();
        formdata.append("song_id", id);
        formdata.append("id", main_playlist_id);
  
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.addSong}`
        );
  
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          formdata
        );
  
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify({
              token: getToken
          })
        );
  
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.apiPostMethod
      );
      this.setState({isAddToPlaylistLoading: true});
      this.setState({addEpisodeId: id})
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleEpisodesListResponse = async(from: string, message: Message) => {
      let apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
  
      if(apiResponse?.message === "Song added successfully") {
        this.setState({isAddToPlaylistLoading: false})
        const data = {
          id: this.state.selectedSearchPodcast.podcastId,
          title: this.state.selectedSearchPodcast.name,
          subtitle: this.state.selectedSearchPodcast.description,
          cover_image: this.state.selectedSearchPodcast.cover
        }
        this.handleCarouselCardClick(data)
      }
      else {
        this.setState({isAddToPlaylistLoading: false})
        this.showAlert("Error", "Song Not Added")
      }
    }
  
    handleCloseModal = () => {
      this.setState({openPlaylistModal: false});
    }

    handleDoneBtn = () => {
      this.getMainPlaylist();
      this.setState({openPlaylistModal: false});
    }

    handleDeleteSong = async(data: ISelectedPlaylist) => {
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      const token = await getStorageData('token')
  
      let storageToken = token.slice(1, -1);
    
      this.getDeletePlaylistAPICallId = requestMessage?.messageId
  
      const mainPlaylistID  = await getStorageData('playlist_id')
  
      let formdata = new FormData();
      formdata.append("song_id", data.id);
      formdata.append("id", mainPlaylistID);
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.removeSong}`
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formdata
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify({
            token: storageToken
        })
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.deleteMethodtype
      );
    this.setState({isDeleteLoading: true});
    runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleSongDeleteResponse = async(from: string, message: Message) => {
      let responseDelete = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
  
      if(responseDelete?.message === "Song removed successfully") {
        this.setState({isDeleteLoading: false})
        this.showPlaylist()
        this.getMainPlaylist();
      }
      else {
        this.setState({isDeleteLoading: false})
        this.showAlert("Error", "Song Not Removed")
      }
    }
    
    checkIsEpisodeAdded = (id: string) => {
      return this.state.selectedPlaylistInfo.some((playlist) => playlist.id === id);
    }

    handleFollowingClick = async(data: IPodcastAttributes) => {
      const reqMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
    
      this.followPodcastCallId = reqMessage.messageId

      const token = await getStorageData('token')

      let cleanedToken = token.slice(1, -1);

      const body = {
        data: {
          followable_type: "BxBlockLogin::Podcast", 
          followable_id: data.id
        }
      }
      reqMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );

      reqMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.createfollow}`
      );

      reqMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify({
            "Content-Type": configJSON.validationApiContentType,
            token: cleanedToken
        })
      );

      reqMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.apiPostMethod
      );
      runEngine.sendMessage(reqMessage.id, reqMessage);
    }

    handleFollowResponse = async(from: string, message: Message) => {
      let followResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if(followResponse.meta.message === "Successfully Followed") {
        this.showPodcastsList()
      }
    }

    handleUnFollowingClick = async(data: IPodcastAttributes) => {
      const requestMsg = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
    
      this.unFollowPodcastCallId = requestMsg.messageId

      const token = await getStorageData('token')

      let cleanedToken = token.slice(1, -1);

      requestMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.unfollow}${data.follow_id}`
      );

      requestMsg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify({
            "Content-Type": configJSON.validationApiContentType,
            token: cleanedToken
        })
      );

      requestMsg.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.deleteMethodtype
      );
      runEngine.sendMessage(requestMsg.id, requestMsg);
    }

    handleUnfolowResponse = async(from: string, message: Message) => {
      let unfollowResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if(unfollowResponse.message === "Successfully Unfollowed") {
        this.showPodcastsList()
      }
    }
}
