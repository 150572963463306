import React  from 'react'
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

// Customizable Area Start
import { business } from "../assets"
import { prev, next, sponsored1, suggested1, suggested2 } from "../../../dashboard/src/assets";
import { getStorageData } from "../../../../framework/src/Utilities";
interface ArrowProps {
    onClick: () => void;
  }
export interface ApiCallParamiterInterface {
  contentType: string,
  method: string,
  endPoint: string
}

export interface VaildResponse {
  data: {
    attributes: {
      podcast_categories_ids: [string]
    }
  }
}

export interface PodcastData {
  id:string,
  attributes: {
    follower: {
      following: boolean
    },
    cover_image: string,
    name: string,
    description: string,
    categories: {
      data: [{
        id: string,
        attributes: {
          name: string,
          image: {
            url: string
          },
        }
      }]
    }
  }
}

export interface PodcastEpisodesData {
  id:string,
  attributes: {
    liked_episode: {
      liked: boolean
    },
    cover: string,
    name: string,
    description: string,
    save_episode: {
      saved: boolean,
      saved_id: null
    }
  }
}
interface TrandingDataList {
  data: Array<PodcastData>
}

interface TrandingEpisodesDataList {
  data: Array<PodcastEpisodesData>
}
interface SubCategoriesDataObject {
  id: string,
  type: string,
  attributes: {
    id: string,
    name: string,
    categories: [
      {
        id: string,
        name: string
      },
    ],
    image: string
  }
}
interface SubCategoriesData {
  data: Array<SubCategoriesDataObject>
}
// Customizable Area End

export const configJSON = require("../config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  handleCommonPath: (path:string) => void;
  handleAddAudio: (value: string, id: string) => void;
  handlePodcastId: (id:string) => void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  sponsoredPost: any,
  suggestedPodcast: any,
  trendingPodcast: Array<PodcastData>,
  statesDropdown: Array<string>,
  categorieName: string,
  subCatData: Array<{ name: string, id: string, data: Array<PodcastData> }>
  trendingEpisode: Array<PodcastEpisodesData>,
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class SubCategoriesController extends BlockComponent<Props, S, SS> {
// Customizable Area Start
  getProductApiCallId: any;
  getPodCastApiCallId: string = "";
  getEpisodesApiCallId: string = "";
  getTrendingPodcastApiCallId: string = "";
  getTrendingEpisodeApiCallId: string = "";
  getTrendingPodcastSubApiCallId: string = "";
  unSaveEpisodeCallId: string = "";
  saveEpisodeCallId: string = ""
// Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
    // Customizable Area End

    this.state = {
    // Customizable Area Start
    sponsoredPost: [],
    suggestedPodcast: [],
    trendingPodcast: [],
    statesDropdown: [],
    categorieName: "",
    subCatData: [],
    trendingEpisode: []
    // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
      this.handleGetSubCategories();
    this.setState({
      sponsoredPost: [
        {id: 1, src: sponsored1, alt: "image1", episode: "Episode 12", name: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis ."}, 
        {id: 2, src: sponsored1, alt: "image2", episode: "Episode 12", name: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis ."},
        {id: 3, src: sponsored1, alt: "image3", episode: "Episode 12", name: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis ."},
        {id: 4, src: sponsored1, alt: "image4", episode: "Episode 12", name: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis ."},
        {id: 5, src: sponsored1, alt: "image5", episode: "Episode 12", name: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis ."},
        {id: 6, src: sponsored1, alt: "image6", episode: "Episode 12", name: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis ."},
        {id: 7, src: sponsored1, alt: "image7", episode: "Episode 12", name: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis ."},
      ],
      statesDropdown : [
        "Careers",
        "Entrepreneurship",
        "Investing",
        "Management",
        "Marketing",
        "Non-Profit"
      ]
      })
}

// Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      if (this.isValidResponse(responseJson)) {
        this.apiSuccessCallBacks(apiRequestCallId, responseJson);
      }

      if(this.saveEpisodeCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))){
        this.handleResForSaveEpisode(message)
      }

      if(this.unSaveEpisodeCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))){
        this.handleResForUnSaveEpisode(message);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start


  getSponsoredSettings() {
    const innerWidth = typeof window !== 'undefined' ? window.innerWidth : 0;
  
    return {
      dots: true,
      infinite: false,
      speed: 800,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      nextArrow: React.createElement(this.nextSponsoredPostArrow, { onClick: () => {}, innerWidth }),
      prevArrow: React.createElement(this.prevSponsoredPostArrow, { onClick: () => {}, innerWidth }),
    };
  }

 
  prevSponsoredPostArrow = (props: ArrowProps & { innerWidth: number }) => {
    const { onClick, innerWidth } = props
  
    return (
      <img
        src={prev}
        onClick={onClick}
        style={{
          position: 'absolute',
          left: "-6%",
          top: '50%',
          transform: 'translateY(-50%)',
          cursor: 'pointer',
          zIndex: 1,
        }}
        alt="Previous Arrow"
      />
    );
  };

  nextSponsoredPostArrow = (props: ArrowProps & { innerWidth: number }) => {
    const { onClick, innerWidth } = props

    return (
      <img
        src={next} 
        onClick={onClick} 
        style={{
          position: 'absolute',
          right: "-6%",
          top: '50%',
          transform: 'translateY(-50%)',
          cursor: 'pointer',
          zIndex: 1,
        }} 
        alt="Next Arrow"
      />
    );
  }

  apiCall = async (data: ApiCallParamiterInterface) => {
    const { method, contentType, endPoint } = data;

    let token = await getStorageData("token");

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        token: JSON.parse(token),
        'Content-Type': contentType,
      })
    )  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    )
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  isValidResponse = (responseJson: VaildResponse) => {
    return responseJson && (responseJson.data);
  };

  apiSuccessCallBacks = (apiRequestCallId: string, responseJson: VaildResponse  & TrandingDataList & TrandingEpisodesDataList & SubCategoriesData) => {
    if(apiRequestCallId == this.getPodCastApiCallId){
      this.handleGetSubCategoriesSuccess(responseJson)
    }
    if (apiRequestCallId == this.getTrendingPodcastApiCallId) {
      this.handleGetTrendingPodcastSuccess(responseJson)
    }
    if (apiRequestCallId == this.getTrendingEpisodeApiCallId) {
      this.handleGetTrendingEpisodeSuccess(responseJson)
    }
    if (apiRequestCallId == this.getTrendingPodcastSubApiCallId) {
      this.handleGetLoopTrendingPodcastApiSuccess(responseJson)
    }
  };

  handleGetSubCategories = async() => {
    let getSubCatID = await getStorageData("subCatId");
    this.getPodCastApiCallId = await this.apiCall({
      contentType: configJSON.categoryApiContentType,
      method: configJSON.httpGetType,
      endPoint: `${configJSON.getSubCategoriesPodcastApiEndPoint}${getSubCatID}`
    });
  }

  handleGetSubCategoriesSuccess = async(responseJson: SubCategoriesData) => {
    let categorieName: string = await getStorageData("catName");
    let subCatId: string = await getStorageData("subCatId");
    await this.handleGetTrendingPodcast(subCatId);
    await this.handleGetTrendingEpodcast(subCatId);
    let data = responseJson.data;
    let subCatData = data.map((item: SubCategoriesDataObject) => ({ name: item.attributes.name, id: item.id, data: [] }));
    await this.handleGetLoopTrendingPodcastData(subCatData);
    this.setState({ subCatData, categorieName });
  }

  handleGetTrendingPodcast = async(id:string) => {
    this.getTrendingPodcastApiCallId = await this.apiCall({
      contentType: configJSON.categoryApiContentType,
      method: configJSON.httpGetType,
      endPoint: `${configJSON.getTrendingPodcastApiEndPoint}${id}&${configJSON.getPerPageData}`
    });
  }

  handleGetTrendingPodcastSuccess = async (responseJson: TrandingDataList) => {
    this.setState({ trendingPodcast: responseJson.data });
  }

  handleGetTrendingEpodcast = async (id: string) => {
    this.getTrendingEpisodeApiCallId = await this.apiCall({
      contentType: configJSON.categoryApiContentType,
      method: configJSON.httpGetType,
      endPoint: `${configJSON.getTrendingEpisodeApiEndPoint}${id}&${configJSON.getPerPageData}`
    });
  }

  handleGetTrendingEpisodeSuccess = async (responseJson: TrandingEpisodesDataList) => {
    this.setState({ trendingEpisode: responseJson.data });
  }

  handleGetLoopTrendingPodcastData = async (data:Array<{ name: string, id: string, data: Array<PodcastData> }>) => {
    data.forEach((item:{ name: string, id: string, data: Array<PodcastData> })=>{
     this.handleGetLoopTrendingPodcastApiCall(item.id)
    })
  }

  handleGetLoopTrendingPodcastApiCall = async (id:string) => {
    this.getTrendingPodcastSubApiCallId = await this.apiCall({
      contentType: configJSON.categoryApiContentType,
      method: configJSON.httpGetType,
      endPoint: `${configJSON.getTrendingPodcastApiEndPoint}${id}&${configJSON.getPerPageData}`
    });
  }

  handleGetLoopTrendingPodcastApiSuccess = async (responseJson: TrandingDataList) => {
    return responseJson.data;
  }

  handleSave = async(id:number) =>{
    
    let token = await getStorageData("token");
    const header = {
      "Content-Type": "application/json",
      token: JSON.parse(token),
    };
    const body = {
      data: {
        saveable_type: "BxBlockLogin::Episode", 
        saveable_id: id
    }
  }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.saveEpisodeCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.saveEpisode}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleResForSaveEpisode = async (message: Message) => {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (responseJson.data.id) {
      this.handleGetSubCategories()
    }
  };

  handleUnSave = async(id:number) =>{
    let token = await getStorageData("token");
    const header = {
      token: JSON.parse(token),
    };
   
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.unSaveEpisodeCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.saveEpisode}/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );   
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleResForUnSaveEpisode  = async (message: Message) => {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)); 
    if (responseJson.message == "Successfully Unsaved") {
      this.handleGetSubCategories()                     
    }
  };

  // Customizable Area End
}
