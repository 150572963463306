// Customizable Area Start
import React from "react";
import {
  Box,
  styled,
} from "@mui/material";
import Slider from "react-slick";
import { goBack } from "../../email-account-login/src/assets";
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined';
import ShareIcon from '@mui/icons-material/Share';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import ImageCard from "../../audiolibrary/src/components/ImageCard.web";
import Footer from "../../navigationmenu/src/Footer.web";
import { getStorageData } from "../../../framework/src/Utilities";
import { ResolveCondition } from "../../utilities/src/CustomHelpers";
import { ConditionJsx } from "../../utilities/src/ConditionJsx";
import AllSearchListController, { Props } from "./AllSearchListController";
import moment from "moment";
import { like } from "../../audiolibrary/src/assets";
import { addBtn, addedBtn, heart, selectedBtn } from "../../dashboard/src/assets";

export default class AllSearchList extends AllSearchListController {
  constructor(props: Props) {
    super(props);
  }
  render() {

    return (
      <>
        <StyledWrapperAllSearchList>
          <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Box className="mainTitle" id="sponsoredContent" style={{ color: "white", display: "flex", alignItems: "center", marginTop: "10px", marginBottom: "10px", marginLeft: "3%", fontFamily: "Nexa-Heavy", fontSize: "30px", fontWeight: 900 }}>
              <img
                src={goBack}
                alt="goBack"
                style={{ marginRight: "20px", cursor: "pointer", color: "white" }}
                data-test-id="navigate"
                onClick={async () => {
                  let prevPath = await getStorageData("previousAllSearchResult")
                  let parsedPath = JSON.parse(prevPath).previousAllSearchResult
                  this.props.handleCommonPath(parsedPath)
                }
                }
              />
              All Search Results
            </Box>
            <Box style={{ display: "flex", alignItems: "center", color: "white", marginRight: "3%", width: "25%" }}>
              <div style={{ paddingRight: "3%" }}>Filter by:</div>
              <Stack direction="row" spacing={1} >
                <Chip label="Channel"
                  className={ResolveCondition(this.state.filterCategory.length == 7, "filterCategory filteredCategories", "filterCategory")}
                  data-test-id="filterCategory"
                  onClick={() => {
                    this.setState({ filterCategory: "podcast" }, this.getSearchResultsApi)
                  }} />

                <Chip label="Episode"
                  data-test-id="filterEpisode"
                  className={ResolveCondition(this.state.filterCategory.length == 8, "filterCategory filteredCategories", "filterCategory")}
                  onClick={() => {
                    this.setState({ filterCategory: "episodes" }, this.getSearchResultsApi)
                  }} />
                <Chip label="Post"
                  className={ResolveCondition(this.state.filterCategory.length == 5, "filterCategory filteredCategories", "filterCategory")}
                  data-test-id="filterPosts"
                  onClick={() => {
                    this.setState({ filterCategory: "posts" }, this.getSearchResultsApi)
                  }} />
              </Stack>
            </Box>
          </Box>
          {ConditionJsx(this.state.filterCategory.length == 0, <>
            {ConditionJsx(this.state.searchPodcast.length > 0, <>  <Box style={{ color: "white", width: "90%", margin: "auto", marginTop: "36px", fontFamily: "Nexa-Heavy", fontWeight: 900, fontSize: "30px" }}>
              <div onClick={this.handleNavigateToChannel} data-test-id={"handleNavigateToChannel"}>Channel</div>
            </Box>
              <Box style={{ width: "90%", margin: "auto", marginTop: "3%", height: "379px" }}>

                <StyledSlider {...this.getSuggestedSlickSettings()}>

                  {
                    this.state.searchPodcast && this.state.searchPodcast?.map((item: any) => {

                      return (
                        <div key={item.id} id="podcasts">
                          <div style={webStyles.suggestedImgBody} id="podcasts">
                            <img
                              style={{ ...webStyles.image, height: "223px" }}
                              src={item.attributes?.cover_image} onClick={() => { this.props.handlePodcastId(item.id) }} />
                            {item.attributes.follower.following ?
                              <div style={{ textAlign: "center", position: "absolute", right: "10px", top: "15px", color: "white", background: "black", height: "31px", width: "108px", borderRadius: "8px", fontFamily: "Nexa-Heavy", fontStyle: "16px", fontWeight: 900 }} data-test-id="unfollowPodcast" onClick={() => { this.handleUnFollowPodcast(item.attributes.follower.follow_id) }}>Following</div> :
                              <div style={{ textAlign: "center", position: "absolute", right: "10px", top: "15px", color: "white", background: "#FF0807", height: "31px", width: "84px", borderRadius: "8px", fontFamily: "Nexa-Heavy", fontStyle: "16px", fontWeight: 900 }} data-test-id="followPodcast" onClick={() => { this.handleFollowPodcast(item.id) }}>Follow</div>
                            }
                            <div style={webStyles.description} id="podcasts">
                              <Box
                                style={{ ...webStyles.episode, fontWeight: 900, fontFamily: "Nexa-Heavy" }} id="podcasts">
                                {item.attributes?.name.toUpperCase()}
                              </Box>
                              <Box id="podcasts"
                                style={{ ...webStyles.episode, fontWeight: 900, fontFamily: "Nexa-Heavy", textTransform: "capitalize" }}>
                                {`${item.attributes?.hosts[0]?.first_name} ${item.attributes?.hosts[0]?.last_name}`}
                              </Box>
                            </div>
                          </div>
                        </div>
                      )
                    })
                  }
                </StyledSlider>
              </Box></>, <></>)}

            {ConditionJsx(this.state.searchEpisode.length > 0, <>
              <Box style={{ color: "white", width: "90%", margin: "auto", fontFamily: "Nexa-Heavy", fontWeight: 900, fontSize: "30px" }}>
                <div>Episodes</div>
              </Box>
              <Box style={{ width: "90%", margin: "auto", marginTop: "3%" }}>
                <StyledSlider {...this.getSuggestedSlickSettings2()}>
                  {
                    this.state.searchEpisode && this.state.searchEpisode?.map((item: any) => {

                      return (
                        <div key={item.id} id="episode">  
                          <div style={webStyles.suggestedImgBody}>
                            <img data-test-id="imageClickID" id="episode" style={{ ...webStyles.image, height: "223px" }} src={item.attributes.cover} alt={item.attributes.name} onClick={() => { this.props.handlePodcastId(item.id) }} />
                            {item.attributes.save_episode.saved ?
                              <img src={selectedBtn} alt="addedBtn" id="episode" style={{ position: "absolute", right: "10px", top: "10px" }} data-test-id="unsaveClick" onClick={() => { this.handleUnSave(item.attributes.save_episode.saved_id) }} /> :
                              <img src={heart} alt="saveBtn" id="episode" style={{ position: "absolute", right: "10px", top: "10px" }} data-test-id="saveClick" onClick={() => { this.handleSave(item.id) }} />
                            }
                            {item.added ?
                              <img src={addedBtn} alt="addedBtn" style={{ position: "absolute", right: "10px", top: "50px" }} /> :
                              <img src={addBtn} alt="saveBtn" style={{ position: "absolute", right: "10px", top: "50px" }} />
                            }
                            <div style={webStyles.description}>
                              <Box id="episode" style={{ ...webStyles.episode, fontWeight: 900, fontFamily: "Nexa-Heavy" }}>
                                {item.attributes?.name.toUpperCase()}
                              </Box>
                              <Box id="episode" style={{ ...webStyles.episode, fontWeight: 900, fontFamily: "Nexa-Heavy", textTransform: "capitalize" }}>
                                {`${item.attributes?.account?.first_name} ${item.attributes?.account?.last_name}`}
                              </Box>
                            </div>
                          </div>
                        </div>
                      )
                    })
                  }
                </StyledSlider>
              </Box></>, <></>)}
            {ConditionJsx(this.state.searchPost.length > 0, <>
              <Box style={{ color: "white", width: "90%", margin: "auto", fontFamily: "Nexa-Heavy", fontWeight: 900, fontSize: "30px" }}>
                <div>Posts</div>
              </Box>
              <Box style={{ width: "90%", margin: "auto", marginTop: "3%" }}>
                <StyledSlider {...this.getSuggestedSlickSettings1()}>
                  {
                    this.state.searchPost && this.state.searchPost?.map((item: any, index: number) => {

                      return (
                        <div key={item.id} id="posts" style={{ display: "flex" }}>
                          <div id="posts" style={{ display: "flex", background: "#312F2D", borderRadius: "20px", justifyContent: "center", marginBottom: "30px", flexDirection: "column" }}>
                            <Box id="posts" style={{ height: "auto", width: "90%", marginTop: "41px", margin: "auto" }}>
                              <div style={{ marginTop: "50px", display: "flex", justifyContent: "space-between" }}>
                                <div id="posts" style={{ height: "56px", width: "59px" }}>
                                  <img src={item?.attributes?.account?.attributes?.profile_picture} alt="search" style={{ height: "56px", width: "59px", borderRadius: "100px" }} />
                                </div>
                                <div id="posts" style={{ color: "white", height: "20%", width: "92%" }}>
                                  <div style={{ fontFamily: "Nexa-Heavy", fontWeight: 900, fontSize: "14px" }}>{item?.attributes?.account?.attributes?.first_name} {item?.attributes?.account?.attributes?.last_name}</div>
                                  <div style={{ marginTop: "20px", fontFamily: "Nexa-Regular", fontSize: "14px", fontWeight: 100 }}>{item.attributes?.body}</div>
                                </div>
                              </div>
                            </Box>
                            <div id="posts" style={{ margin: "auto" }}>
                              {/* <img src={item.attributes?.photos[0]?.photot_link} alt="post" style={{ width: "766px", height: "308px", borderRadius: "43px", marginTop: "30px" }} /> */}
                            </div>
                            <div id="posts" style={{ display: "flex", marginTop: "50px", color: "white", width: "30%", marginBottom: "50px", marginLeft: "11%", justifyContent: "space-around" }}>
                              <div style={{ fontFamily: "Nexa-Heavy", fontSize: "14px" }}>{moment(item.attributes.created_at).format("MM/DD/YY")}</div>
                              <div style={{ display: "flex", alignItems: "center" }} id="like">
                                {
                                  item.attributes.liked_post.liked ? (
                                    <img
                                      id="imgLike"
                                      src={like}
                                      alt="Liked"
                                      style={{ height: "30px", width: "30px", cursor: "pointer", marginRight: "10px" }}
                                      data-test-id="handleLikeButton"
                                      onClick={() => { this.handleUnLike(item.attributes.liked_post.liked_id); }}
                                    />
                                  ) : (
                                    <ThumbUpAltOutlinedIcon
                                      id="dislike"
                                      style={{ color: 'white', fontSize: 25, cursor: "pointer", marginRight: "10px" }}
                                      onClick={() => { this.handleLike(item.id); }}
                                      data-test-id="dislikeButton"
                                    />
                                  )}
                                <span className={ResolveCondition(item.attributes.liked_post.liked, "likedCount", "count")} >{item.attributes.liked_post.count}</span>
                              </div>
                              <div id="posts" style={{ display: "flex", alignItems: "center" }}>
                                <ChatBubbleOutlineIcon
                                  style={{ color: 'white', fontSize: 25, cursor: "pointer", marginRight: "10px" }}
                                />
                                <span style={{ fontFamily: "Nexa-Heavy", fontSize: "14px" }}>{item.attributes.comments.data.length}</span>
                              </div>
                              <div id="posts" style={{ display: "flex", alignItems: "center" }}>
                                <ShareIcon
                                  style={{ color: 'white', fontSize: 25, cursor: "pointer", marginRight: "10px" }}
                                />
                                <span style={{ fontFamily: "Nexa-Heavy", fontSize: "14px" }}>984</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    })
                  }

                </StyledSlider>
              </Box></>, <></>)}
          </>, <></>)
          }
          {ConditionJsx(this.state.filterCategory.length == 7, (
            <Box style={{ margin: "auto", width: "95%" }}>

              <Box style={{ color: "white", marginTop: "36px", marginBottom: "30px", fontFamily: "Nexa-Heavy", fontWeight: 900, fontSize: "30px" }}>
                <div>Channel</div>
              </Box>
              <Box display={"flex"} gap={3} flexWrap={"wrap"} justifyContent={"flex-start"} alignItems={"center"}>
                {
                  this.state.filteredChannels.channel && this.state.filteredChannels.channel.map((item: any, index: number) => (
                    <div key={item.id}>
                      <div style={webStyles.suggestedImgBody}>
                        <img style={{ ...webStyles.image, height: "223px" }} src={item.attributes?.cover_image} onClick={() => { this.props.handlePodcastId(item.id) }} />
                        {item.attributes.follower.following ?
                          <div style={{ textAlign: "center", position: "absolute", right: "10px", top: "15px", color: "white", background: "black", height: "31px", width: "108px", borderRadius: "8px", fontFamily: "Nexa-Heavy", fontStyle: "16px", fontWeight: 900 }} data-test-id="unfollowPodcast" onClick={() => { this.handleUnFollowPodcast(item.attributes.follower.follow_id) }}>Following</div> :
                          <div style={{ textAlign: "center", position: "absolute", right: "10px", top: "15px", color: "white", background: "#FF0807", height: "31px", width: "84px", borderRadius: "8px", fontFamily: "Nexa-Heavy", fontStyle: "16px", fontWeight: 900 }} data-test-id="followPodcast" onClick={() => { this.handleFollowPodcast(item.id) }}>Follow</div>
                        }
                        <div style={webStyles.description}>
                          <Box style={{ ...webStyles.episode, fontWeight: 900, fontFamily: "Nexa-Heavy" }}>
                            {item.attributes?.name.toUpperCase()}
                          </Box>
                          <Box style={{ ...webStyles.episode, fontWeight: 900, fontFamily: "Nexa-Heavy", textTransform: "capitalize" }}>
                            {`${item.attributes?.hosts[0]?.first_name} ${item.attributes?.hosts[0]?.last_name}`}
                          </Box>
                        </div>
                      </div>
                    </div>
                  ))
                }
              </Box>
            </Box>
          ), <></>)
          }

          {ConditionJsx(this.state.filterCategory.length == 8, (
            <Box style={{ margin: "auto", width: "95%" }}>

              <Box style={{ color: "white", marginTop: "36px", marginBottom: "30px", fontFamily: "Nexa-Heavy", fontWeight: 900, fontSize: "30px" }}>
                <div>Episodes</div>
              </Box>
              <Box display={"flex"} gap={3} flexWrap={"wrap"} justifyContent={"flex-start"} alignItems={"center"}>
                {
                  this.state.filteredChannels.episode && this.state.filteredChannels.episode.map((item: any, index: number) => (
                    <div key={item.id}>
                    <div style={webStyles.suggestedImgBody}>
                      <img data-test-id="imageClickID" style={{ ...webStyles.image, height: "223px" }} src={item.attributes.cover} alt={item.attributes.name} onClick={() => { this.props.handlePodcastId(item.id) }} />
                      {item.attributes.save_episode.saved ?
                        <img src={selectedBtn} alt="addedBtn" style={{ position: "absolute", right: "10px", top: "10px" }} data-test-id="unsaveClick" onClick={() => { this.handleUnSave(item.attributes.save_episode.saved_id) }} /> :
                        <img src={heart} alt="saveBtn" style={{ position: "absolute", right: "10px", top: "10px" }} data-test-id="saveClick" onClick={() => { this.handleSave(item.id) }} />
                      }
                      {item.added ?
                        <img src={addedBtn} alt="addedBtn" style={{ position: "absolute", right: "10px", top: "50px" }} /> :
                        <img src={addBtn} alt="saveBtn" style={{ position: "absolute", right: "10px", top: "50px" }} />
                      }
                      <div style={webStyles.description}>
                        <Box style={{ ...webStyles.episode, fontWeight: 900, fontFamily: "Nexa-Heavy" }}>
                          {item.attributes?.name.toUpperCase()}
                        </Box>
                        <Box style={{ ...webStyles.episode, fontWeight: 900, fontFamily: "Nexa-Heavy", textTransform: "capitalize" }}>
                          {`${item.attributes?.account?.first_name} ${item.attributes?.account?.last_name}`}
                        </Box>
                      </div>
                    </div>
                  </div>
                  ))
                }
              </Box>
            </Box>
          ), <></>)
          }

          {
            ConditionJsx(this.state.filterCategory.length == 5, (
              <>
                <Box style={{ color: "white", width: "90%", margin: "auto", marginTop: "36px", marginBottom: "30px", fontFamily: "Nexa-Heavy", fontWeight: 900, fontSize: "30px" }}>
                  <div>Posts</div>
                </Box>
                {
                  this.state.filteredChannels.post && this.state.filteredChannels.post.map((item: any) => {
                    return (
                      <div key={1} style={{ margin: "auto", width: "90%" }}>
                        <div style={{ display: "flex", background: "#312F2D", borderRadius: "20px", justifyContent: "center", marginBottom: "30px", flexDirection: "column" }}>
                          <Box style={{ height: "auto", width: "90%", marginTop: "41px", margin: "auto" }}>
                            <div style={{ marginTop: "50px", display: "flex", justifyContent: "space-between" }}>
                              <div style={{ height: "56px", width: "59px" }}>
                                {/* <img src={item?.attributes?.account?.attributes?.profile_picture} alt="search" style={{ height: "56px", width: "59px", borderRadius: "100px" }} /> */}
                              </div>
                              <div style={{ color: "white", height: "20%", width: "92%" }}>
                                <div style={{ fontFamily: "Nexa-Heavy", fontWeight: 900, fontSize: "14px" }}>{item?.attributes?.account?.attributes?.first_name} {item?.attributes?.account?.attributes?.last_name}</div>
                                <div style={{ marginTop: "20px", fontFamily: "Nexa-Regular", fontSize: "14px", fontWeight: 100 }}>{item.attributes?.body}</div>
                              </div>
                            </div>
                          </Box>
                          <div style={{ margin: "auto" }}>
                            {/* <img src={item.attributes?.photos[0]?.photot_link} alt="post" style={{ width: "766px", height: "308px", borderRadius: "43px", marginTop: "30px" }} /> */}
                          </div>
                          <div style={{ display: "flex", marginTop: "50px", color: "white", width: "30%", marginBottom: "50px", marginLeft: "11%", justifyContent: "space-around" }}>
                            <div style={{ fontFamily: "Nexa-Heavy", fontSize: "14px" }}>{moment(item.attributes?.created_at).format("MM/DD/YY")}</div>
                            <div style={{ display: "flex", alignItems: "center" }}>
                              {
                                item.attributes?.liked_post?.liked ? (
                                  <img src={like} alt="Liked"
                                    data-test-id="handleLikeButton"
                                    style={{ height: "30px", width: "30px", cursor: "pointer", marginRight: "10px" }}
                                    onClick={() => {
                                      this.handleUnLike(item.attributes.liked_post.liked_id);
                                    }}
                                  />
                                ) : (
                                  <ThumbUpAltOutlinedIcon
                                    onClick={() => {
                                      this.handleLike(item.id);
                                    }}
                                    style={{ color: 'white', fontSize: 25, cursor: "pointer", marginRight: "10px" }}
                                    data-test-id="dislikeButton"
                                  />
                                )}
                              <span className={ResolveCondition(item.attributes?.liked_post?.liked, "likedCount", "count")}>{item.attributes?.liked_post.count}</span>
                            </div>
                            <div style={{ display: "flex", alignItems: "center" }}>
                              <ChatBubbleOutlineIcon
                                style={{ color: 'white', fontSize: 25, cursor: "pointer", marginRight: "10px" }}
                              />
                              <span style={{ fontFamily: "Nexa-Heavy", fontSize: "14px" }}>{item.attributes?.comments.data.length}</span>
                            </div>
                            <div style={{ display: "flex", alignItems: "center" }}>
                              <ShareIcon
                                style={{ color: 'white', fontSize: 25, cursor: "pointer", marginRight: "10px" }}
                              />
                              <span style={{ fontFamily: "Nexa-Heavy", fontSize: "14px" }}>984</span>
                            </div>

                          </div>
                        </div>
                      </div>
                    )
                  })
                }
              </>
            ), <></>)
          }
        </StyledWrapperAllSearchList>
        <Footer navigation={this.props.navigation} id={"1"} />
      </>
    )
  }
}

const StyledWrapperAllSearchList = styled('div')({
  "& *": {
    boxSizing: "border-box",
  },
  "& .likedCount": {
    fontFamily: "Nexa-Heavy",
    fontSize: "14px",
    color: "red"
  },
  "& .count": {
    fontFamily: "Nexa-Heavy",
    fontSize: "14px"
  },
  "& .filterCategory": {
    background: "white",
    color: "black",
    fontWeight: 900,
    fontSize: "14px",
    fontFamily: "Nexa-Heavy",
    height: "32px",
    width: "91px",
    "&:hover": {
      background: "white",
      color: "black",
    }
  },
  "& .filteredCategories": {
    background: "#FF0807",
    color: "white",
    "&:hover": {
      background: "#FF0807",
      color: "white",
    }
  }
})

const webStyles = {
  container: {
    padding: "20px 50px"
  } as const,
  image: {
    objectFit: "cover" as const,
    borderRadius: "8px",
    width: '100%',
    height: "307px",
    display: "flex",
    margin: "0 auto",
  },
  imgBody: {
    position: 'relative' as const,
  },
  sponsoredButton: {
    color: "#fff",
    backgroundColor: "#FF0807",
    padding: '10px 16px',
    cursor: 'pointer',
    border: 'none',
    borderRadius: '8px',
    fontWeight: 900,
    fontSize: "14px"
  },
  episode: {
    color: "#fff",
    fontSize: '14px',
    lineHeight: '22px',
    fontWeight: 600
  },
  sponsoredImgBody: {
    height: "370px",
    width: '100%',
    position: 'relative' as const
  },
  title: {
    fontWeight: 900,
    fontSize: "30px",
    color: '#fff',
    marginBottom: "10px"
  },

  suggestedImgBody: {
    width: '227px',
    height: "223px",
    position: 'relative' as const
  },
  postBody: {
    width: "100%",
    height: "25%",
    backgroundColor: "#312F2D",
    position: 'relative' as const
  },
  description: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    height: "76px",
    backgroundColor: "#44403c",
    borderBottomLeftRadius: "16px",
    borderBottomRightRadius: "16px",
    padding: "16px"
  } as React.CSSProperties,

};
const StyledSlider = styled(Slider)`
  .slick-slide {
    z-index: 1; /* Ensure slides are on top */
    pointer-events: none; /* Disable pointer events for slides by default */
  }

  .slick-slide.slick-active {
    pointer-events: auto; /* Enable pointer events for active slides */
  }

  .slick-list {
    overflow: hidden;
    pointer-events: auto; /* Ensure child elements are clickable */
  }

  .slick-dots li {
    margin: 0px !important;
  }

  .slick-dots ul {
    margin: 140px !important;
  }
`;


// Customizable Area End