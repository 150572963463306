// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import React from "react"
import { leftArrow, rightArrow } from "../../dashboard/src/assets";
import { getStorageData } from "../../../framework/src/Utilities";

export const configJSON = require("./config");

interface ArrowProps {
  onClick: () => void;
}

export interface Props {
  navigation: any;
  id: string;
  handleCommonPath: (path: string) => void;
  handlePodcastId: (id: string) => void;
}

interface S {
  searchPodcast: any,
  searchEpisode: any,
  searchPost: any,
  filteredChannels: {
    channel: any,
    episode: any,
    post: any
  };
  filterCategory: any,
  searchValue: any
}

interface SS {
  id: any;
}

export default class AllSearchListController extends BlockComponent<Props, S, SS> {
  searchResultsApiCallId: any = "";
  unlikePostApiCallId: any = "";
  likePostApiCallId: any = "";
  followPodcastCallId:any = "";
  unFollowPodcastCallId: any = "";
  saveEpisodeCallId:any = "";
  unSaveEpisodeCallId:any = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SearchTextMessage)
    ];

    this.state = {
      filterCategory: "",
      searchPodcast: [],
      searchEpisode: [],
      searchPost: [],
      searchValue: "",
      filteredChannels: {
        channel: "",
        episode: "",
        post: ""
      }
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.getSearchResultsApi()
  }
  obtainSearchText = (message: Message) => {
    if (message.id === getName(MessageEnum.SearchTextMessage)) {
      const recievedData = message.getData(
        getName(MessageEnum.SearchMessageText)
      );
      if (recievedData) {
        this.setState({ searchValue: recievedData.searchText }, () => this.getSearchResultsApi())
      }
    }
  }

  async receive(from: string, message: Message) {
    if (this.searchResultsApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      this.handleResForSearchApi(message);
    }
    if (this.likePostApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      this.handleResForLikePost(message);
    }
    if (this.unlikePostApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      this.handleResForUnlikepost(message);
    }
    if(this.followPodcastCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      this.handleResForfollowPodcast(message)
    }
    if(this.unFollowPodcastCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      this.handleResForUnfollowPodcast(message)
    }
    if(this.saveEpisodeCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))){
      this.handleResForSaveEpisode(message);
    }
    if(this.unSaveEpisodeCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))){
      this.handleResForUnSaveEpisode(message);
    }
    this.obtainSearchText(message)
  }

  handleResForSaveEpisode = async (message: Message) => {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));  
    if (responseJson.data.id) {
            this.getSearchResultsApi()
    }
  };

  handleResForUnSaveEpisode  = async (message: Message) => {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)); 
    if (responseJson.message == "Successfully Unsaved") {
      this.getSearchResultsApi()                     
    }
  };  

  handleUnLike = async (id: number) => {
    
    let token = await getStorageData("token");
    const header = {
      token: JSON.parse(token),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.unlikePostApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.unLikePost}/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleLike = async (id: number) => {

    let token = await getStorageData("token");
    const header = {
      "Content-Type": "application/json",
      token: JSON.parse(token),
    };
    const body = {
      data: {
        likeable_type: "BxBlockCommunityforum::Post",
        likeable_id: id
      }
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.likePostApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.likePost}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleUnSave = async(id:number) =>{
    let token = await getStorageData("token");
    const header = {
      token: JSON.parse(token),
    };
   
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.unSaveEpisodeCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.unSaveEpisode}/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );   
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleSave = async(id:number) =>{
    console.log(id,"save id");
    
    
    let token = await getStorageData("token");
    const header = {
      "Content-Type": "application/json",
      token: JSON.parse(token),
    };
    const body = {
      data: {
        saveable_type: "BxBlockLogin::Episode", 
        saveable_id: id
    }
  }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.saveEpisodeCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.saveEpisode}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleResForLikePost = async (message: Message) => {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (responseJson.data.id) {
      this.getSearchResultsApi()
    }
  };

  handleResForUnlikepost = async (message: Message) => {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (responseJson.message == "Successfully destroy") {
      this.getSearchResultsApi()
    }
  };


  handleUnFollowPodcast = async(id:number) =>{
    let token = await getStorageData("token");
    const header = {
      token: JSON.parse(token),
    };
   
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.unFollowPodcastCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.unFollowPodcast}/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );   
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleFollowPodcast = async(id:number) =>{

    let token = await getStorageData("token");
    const header = {
      "Content-Type": "application/json",
      token: JSON.parse(token),
    };
    const body = {
      data: {
        "followable_type": "BxBlockLogin::Podcast",
        "followable_id": id
    }
  }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    this.followPodcastCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.followPodcast}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleResForfollowPodcast = async (message: Message) => {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (responseJson.data.id) {
      this.getSearchResultsApi()
    }
  };

  handleResForUnfollowPodcast = async (message: Message) => {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (responseJson.message == "Successfully Unfollowed") {
      this.getSearchResultsApi()
    }
  };
  
  handleResForSearchApi = async (message: Message) => {
    if (this.searchResultsApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
  
      if (responseJson) {
        const { podcasts, episodes, posts } = responseJson;
  
        this.setState((prevState) => ({
          filteredChannels: {
            ...prevState.filteredChannels,
            ...(podcasts && { channel: podcasts.data }),
            ...(episodes && { episode: episodes.data }),
            ...(posts && { post: posts.data }),
          },
          searchPodcast: podcasts?.data || prevState.searchPodcast,
          searchEpisode: episodes?.data || prevState.searchEpisode,
          searchPost: posts?.data || prevState.searchPost,
        }));
      }
    }
  };

  
  getSearchResultsApi = async () => {
    let token = await getStorageData("token");
    let searchQuery = await getStorageData("previousAllSearchResult");

    let parseQuery = JSON.parse(searchQuery).searchQuery;

    let apiUrl = `${configJSON.searchResults}?query=${this.state.searchValue ? this.state.searchValue : parseQuery}&per_page=6&page=1`;
    if (this.state.filterCategory.length > 0) {
      apiUrl += `&filter=${this.state.filterCategory}`;
    }
    const header = {
      token: JSON.parse(token),
    };

    const searchrequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.searchResultsApiCallId = searchrequest.messageId;
    searchrequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiUrl
    );
    searchrequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    searchrequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(searchrequest.id, searchrequest);
  }


  getSuggestedSlickSettings() {
    const innerWidth = typeof window !== 'undefined' ? window.innerWidth : 0;
    return {
      infinite: false,
      dots: true,
      slidesToScroll: 1,
      initialSlide: 0,
      speed: 800,
      prevArrow: React.createElement(this.prevSponsoredArrow, { onClick: () => { }, innerWidth }),
      slidesToShow: 6,
      nextArrow: React.createElement(this.nextSponsoredArrow, { onClick: () => { }, innerWidth }),     
      responsive: [{
        breakpoint: 1920,
        settings: {
          slidesToScroll: 1,
          slidesToShow: 6,
        },
      },
      {
        breakpoint: 1700,
        settings: {
          slidesToScroll: 1,
          slidesToShow: 6,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 6,
          slidesToScroll: Math.min(4, 6),
        },
      },
      ],
    };
  }

  getSuggestedSlickSettings2() {
    const innerWidth = typeof window !== 'undefined' ? window.innerWidth : 0;

    return {
      dots: true,
      slidesToScroll: 1,
      infinite: false,
      speed: 800,
      initialSlide: 0,
      slidesToShow: 6,
      nextArrow: React.createElement(this.nextSponsoredArrow, { onClick: () => { }, innerWidth }),
      prevArrow: React.createElement(this.prevSponsoredArrow, { onClick: () => { }, innerWidth }),    
      responsive: [
        {
          breakpoint: 1920,
          settings: {
            slidesToShow: 6,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 1700,
          settings: {
            slidesToShow: 6,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 1440,
          settings: {
            slidesToShow: 6,
            slidesToScroll: Math.min(4, 6),
          },
        },
      ],
    };
  }
  getSuggestedSlickSettings1() {
    const innerWidth = typeof window !== 'undefined' ? window.innerWidth : 0;

    return {
      dots: true,
      infinite: false,
      speed: 800,
      slidesToShow: 1,
      slidesToScroll: 1,
      swipeToSlide: false,
      draggable: false,
      initialSlide: 0,     
      nextArrow: React.createElement(this.nextSponsoredArrow, { onClick: () => { }, innerWidth }),
      prevArrow: React.createElement(this.prevSponsoredArrow, { onClick: () => { }, innerWidth }),
      responsive: [
        {
          breakpoint: 1920,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 1700,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 1440,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
  }


  prevSponsoredArrow = (props: ArrowProps & { innerWidth: number }) => {
    const { onClick } = props;

    return (
      <div
        onClick={onClick}
        style={{
          height: "50px",
          width: "50px",
          borderRadius: "50%",
          background: "#FF0807",
          position: "absolute",
          top: "25%",
          left: "-2%",
          transform: "translateY(-50%)",
          cursor: "pointer",
          zIndex: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src={leftArrow}
          style={{
            width: "70%",
            height: "70%",
          }}
          alt="Arrow"
        />
      </div>
    );
  };

  nextSponsoredArrow = (props: ArrowProps & { innerWidth: number }) => {
    const { onClick } = props;

    return (
      <div
        onClick={onClick}
        style={{
          height: "50px",
          width: "50px",
          borderRadius: "50%",
          background: "#FF0807",
          position: "absolute",
          top: "25%",
          right: "-2%",
          transform: "translateY(-50%)",
          cursor: "pointer",
          zIndex: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src={rightArrow}
          style={{
            width: "70%",
            height: "70%",
          }}
          alt="Arrow"
        />
      </div>
    );
  };

  handleNavigateToChannel = () => {
    this.props.handleCommonPath('SearchAllResult')
  }
  // Customizable Area End
}
