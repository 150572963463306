import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import React, { createRef } from 'react'
import { handleNavigation } from "../../utilities/src/CustomHelpers";
import { micImg, telephoneImg } from "./assets";
import { leftArrow, rightArrow } from "../../dashboard/src/assets";
import { getStorageData } from "framework/src/Utilities";
export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    handleCommonPath:(path:string) => void;
}

interface SS {
    id: any;
}

interface IMainPlaylist {
  id: string;
  cover_image: string ; 
  name: string;
  description: string;
  episode_time: string | null; 
  songAdded: boolean;
}

interface IPodcastAttributes {
  id: number;
  title: string | null;
  subtitle: string | null;
  cover_image: string | null;
  following?: boolean;
  follow_id? : string;
}

interface IplaylistInfo {
  title: string;
  image: string;
}

interface IEpisodeList {
  id: string;
  cover: string;
  name: string;
  description: string;
  episode_time: string | null; 
  songAdded: boolean;
}

interface ISelectedPodcast {
  cover: string | null;
  name: string | null;
  description: string | null;
  podcastId: number;
}
  
interface S {
  playlistData: Array<{
    imageUrl: string;
    title: string;
    subtitle: string;
    duration: string;
}>;
selectedPlaylist: number | null;
openPlaylistModal: boolean;
selectedPlaylistData: {
  imageUrl: string;
  title: string;
  description: string;
  playListData: Array<{
    imageUrl: string;
    title: string;
    description: string;
    duration: string;
    added: boolean
  }>;
} | null
mainPlaylistData: IplaylistInfo | null;
mainPlaylistInfo: IMainPlaylist[];
podcastsListInfo: IPodcastAttributes[];
isPodcastLoading: boolean;
searchValue: string;
isEpisodeLoading: boolean;
episodesData: IEpisodeList[];
selectedSearchPodcast: ISelectedPodcast;
isAddEpisodeLoading: boolean;
addEpisodeId: string;
isRemoveSongLoading: boolean
}

interface ArrowProps {
  onClick: () => void;
  
}

export default class AddPlayListController extends BlockComponent<Props, S, SS> {
  getMainPlaylistApiCallId: string = ""
  getPodcastsListApiCallId: string = ""
  searchListApiCallId: string = ""
  searchEpisodeApiCallId: string =""
  addEpisodeApiCallId: string = ""
  getMainPlaylistDelteSongAPICallId: string =""
  unFollowPodcastCallId: string = ""
  followPodcastCallId: string = ""
  boxScrollRef: any = createRef();

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.state = {
        playlistData: [],
        selectedPlaylist: null,
        openPlaylistModal: false,
        selectedPlaylistData: null,
        mainPlaylistInfo: [],
        mainPlaylistData: {
          title:micImg,
          image:""
        },
        podcastsListInfo:[],
        isPodcastLoading: false,
        searchValue:"",
        isEpisodeLoading: false,
        episodesData: [],
        selectedSearchPodcast: {
          cover: "",
          name: "",
          description:"",
          podcastId: 0,
        },
        isAddEpisodeLoading: false,
        addEpisodeId: "",
        isRemoveSongLoading: false
    };

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount(): Promise<void> {
    // Replace `dataList` with your actual API call or data source
    const dataList = [
      {
        imageUrl: micImg,
        title: "The Art of Drink",
        subtitle: "Sed Ut perspicats unde oais",
        duration: "1:50:00",
      },
      {
        imageUrl: micImg,
        title: "Coffee Tactics",
        subtitle: "Exploring the depths of caffeine",
        duration: "2:15:30",
      },
      {
        imageUrl: micImg,
        title: "The Art of Drink",
        subtitle: "Sed Ut perspicats unde oais",
        duration: "1:50:00",
      },
      {
        imageUrl: micImg,
        title: "Coffee Tactics",
        subtitle: "Exploring the depths of caffeine",
        duration: "2:15:30",
      },
      {
        imageUrl: micImg,
        title: "The Art of Drink",
        subtitle: "Sed Ut perspicats unde oais",
        duration: "1:50:00",
      },
      {
        imageUrl: micImg,
        title: "Coffee Tactics",
        subtitle: "Exploring the depths of caffeine",
        duration: "2:15:30",
      },
    ];

    this.getMainPlaylist()
    //carousel data
    this.getPodcastsList();

    // Update the state with fetched data
    this.setState({ playlistData: dataList });

    // mockdata
    const selectedPlaylistMockData = {
      imageUrl: micImg,
      title: "The True Podcast",
      description: "Cost of success",
      playListData: [
        {
          imageUrl: micImg,
          title: "Calm Waters",
          description: "A soothing instrumental track.",
          duration: "3:45",
          added: true,
        },
        {
          imageUrl: micImg,
          title: "Evening Glow",
          description: "Perfect music for an evening stroll.",
          duration: "4:20",
          added: false,
        },
        {
          imageUrl: micImg,
          title: "Midnight Blues",
          description: "A jazzy track for late nights.",
          duration: "5:05",
          added: true,
        },
        {
          imageUrl: micImg,
          title: "Morning Breeze",
          description: "Start your day with this refreshing tune.",
          duration: "2:50",
          added: true,
        },
        {
          imageUrl: micImg,
          title: "Morning Breeze",
          description: "Start your day with this refreshing tune.",
          duration: "2:50",
          added: true,
        },
        {
          imageUrl: micImg,
          title: "Morning Breeze",
          description: "Start your day with this refreshing tune.",
          duration: "2:50",
          added: false,
        },
      ],
    };

    this.setState({selectedPlaylistData: selectedPlaylistMockData})
    setTimeout(() => {
      if (this.boxScrollRef.current) {
        this.boxScrollRef.current.scrollTo({ top: 0, behavior: "smooth" });
      }
    }, 0);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Received", message);
  
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      if(this.getMainPlaylistApiCallId === apiRequestCallId) {
        this.handleMainPlaylistResponse(from, message);
      }

      if(this.getPodcastsListApiCallId === apiRequestCallId) {
        this.handlePodcastsListResponse(from, message);
      }

      if(this.searchListApiCallId === apiRequestCallId) {
        this.handleSearchPodcastsListResponse(from, message);
      }

      if(this.searchEpisodeApiCallId === apiRequestCallId) {
        this.handleSearchEpisodesListResponse(from, message);
      }

      if(this.addEpisodeApiCallId === apiRequestCallId) {
        this.handleAddEpisodesListResponse(from, message);
      }

      if(this.getMainPlaylistDelteSongAPICallId === apiRequestCallId) {
        this.handleDeleteSongResponse(from, message);
      }

      if(this.unFollowPodcastCallId === apiRequestCallId) {
        this.handleUnfolowResponse(from, message);
      }

      if(this.followPodcastCallId === apiRequestCallId) {
        this.handleFollowResponse(from, message)
      }
    }
  }


  getSearchSlickSettings(){
    const innerWidth = typeof window !== 'undefined' ? window.innerWidth : 0;

    return(
       {
        dots: false,
        infinite: false,
        speed: 800,
        slidesToScroll: 1,
        initialSlide: 0,
        nextArrow: React.createElement(this.nextSearchArrow, { onClick: () => {}, innerWidth }),
        prevArrow: React.createElement(this.prevSearchArrow, { onClick: () => {}, innerWidth }),
        responsive: [
          {
            breakpoint: 1920,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1,
            }
          },
          {
            breakpoint: 1440,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 1024, 
            settings: {
              slidesToShow: 2, 
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1, 
              slidesToScroll: 1
            }
          },
       ]
       }
    )
  }

  prevSearchArrow = (props: ArrowProps & { innerWidth: number }) => {
    const { onClick, innerWidth } = props

    return(
      <div onClick={onClick} 
      style={{
        height: "50px", 
        width: "50px", 
        borderRadius: "50%", 
        background: "#FF0807", 
        position: 'absolute',
        top:"50%",
        left: "-2%",
        transform: 'translateY(-50%)',
        cursor: 'pointer',
        zIndex: 1,
      }}>
      <img 
        src={leftArrow} 
        style={{
          width:"100%",
          height:"100%"
        }} 
        alt="Arrow"
      />
      </div>
    )
  }

  nextSearchArrow = (props: ArrowProps & { innerWidth: number }) => {
    const { onClick, innerWidth } = props

    return(
      <div onClick={onClick} style={{
        height: "50px", width: "50px",
        borderRadius: "50%",
        background: "#FF0807",
        position: 'absolute',
        right: "-2%",
        top: '50%',
        transform: 'translateY(-50%)',
        cursor: 'pointer',
        zIndex: 1,
      }}>
        <img
          alt="Arrow"
          src={rightArrow}
          style={{
            width: "100%",
            height: "100%"
          }}
        />
      </div>
    )
  }

  handlePlaylistRowClick = (index: number) => {
    this.setState({ selectedPlaylist: index });
    // this.setState({openPlaylistModal: true})
  }

  handlePlaylistClose = () => {
    this.setState({openPlaylistModal: false})
  }

  handleNavigatePlaylist = () => {
    this.props.handleCommonPath("Playlist")
  }

  getMainPlaylist = async() => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const token = await getStorageData('token')

    let cleanedToken = token.slice(1, -1);

  
    this.getMainPlaylistApiCallId = requestMessage?.messageId

    const getPlaylistId  = await getStorageData('main_playlist_id')     

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
          "Content-Type": configJSON.validationApiContentType,
          token: cleanedToken
      })
      );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.songListApiEndPoint}/${getPlaylistId}`
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleMainPlaylistResponse = async(from: string, message: Message) => {
    let apiResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if(apiResponse?.playlist?.data?.id) {
      const filteredPlaylist: IMainPlaylist[] = 
      apiResponse?.playlist?.data?.attributes?.songs?.data?.map((item: any) => ({
        cover_image: item.attributes.cover || null,
        id: item.id,
        description: item.attributes.description || '',
        name: item.attributes.name || '',
        songAdded: item.attributes.added.song_added,
        episode_time: item.attributes.episode_time || null,
      })) || [];

      this.setState({
        mainPlaylistData: {
          title: apiResponse.playlist.data.attributes.title,
          image: apiResponse.playlist.data.attributes.image ? apiResponse.playlist.data.attributes.image : micImg,
        },
      });
      this.setState({mainPlaylistInfo: filteredPlaylist})
    }
  }

  getPodcastsList = async() => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const token = await getStorageData('token')

    let cleanedToken = token.slice(1, -1);

    this.getPodcastsListApiCallId = requestMessage?.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
          "Content-Type": configJSON.validationApiContentType,
          token: cleanedToken
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiGetMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.listPodCastsEndPoint}`
    );
    this.setState({isPodcastLoading: true});
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handlePodcastsListResponse = async(from: string, message: Message) => {
    let apiResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if(apiResponse?.data?.length > 0) {
      this.setState({isPodcastLoading: false});
      const extractedData = apiResponse?.data?.map((podcast:any) => ({
        title: podcast.attributes.name,
        id: podcast.id,
        cover_image: podcast.attributes.cover_image,
        subtitle: `${podcast.attributes.hosts?.[0]?.first_name ?? ''} ${podcast.attributes.hosts?.[0]?.last_name ?? ''}`.trim(),
        follow_id: podcast.attributes?.follower?.follow_id,
        following: podcast.attributes.follower.following,
      }));
  
      this.setState({podcastsListInfo: extractedData})
    }
    else {
      this.setState({isPodcastLoading: false});
      this.setState({podcastsListInfo: []})
    }
  } 

  handleSearchInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({searchValue: event.target.value})
  }

  handleSearchKeyDown = async(event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      const token = await getStorageData('token')
  
      let cleanedToken = token.slice(1, -1);
  
      this.searchListApiCallId = requestMessage?.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify({
            "Content-Type": configJSON.validationApiContentType,
            token: cleanedToken
        })
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiGetMethod
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.globalSearchEndPoint}?query=${this.state.searchValue}`
      );
      this.setState({isPodcastLoading: true});
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };

  handleSearchPodcastsListResponse = async(from: string, message: Message) => {
    let apiResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if(apiResponse?.podcasts?.data.length > 0){
      this.setState({isPodcastLoading: false});

      const extractedData = apiResponse?.podcasts?.data?.map((podcast:any) => ({
        id: podcast.id,
        title: podcast.attributes.name,
        subtitle: `${podcast.attributes.hosts?.[0]?.first_name ?? ''} ${podcast.attributes.hosts?.[0]?.last_name ?? ''}`.trim(),
        cover_image: podcast.attributes.cover_image,
        following: podcast.attributes.follower.following,
        follow_id: podcast.attributes?.follower?.follow_id,
      }));
      this.setState({podcastsListInfo: extractedData})
    }
    else {
      this.setState({podcastsListInfo: []})
      this.setState({isPodcastLoading: false});
    }
  }

  handleImageCardClick = async(data: IPodcastAttributes) => {
    this.setState({openPlaylistModal: true});
    this.setState({
      selectedSearchPodcast: {
        cover: data.cover_image,
        name: data.title,
        description: data.subtitle,
        podcastId: data.id
      }
    });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const token = await getStorageData('token')
  
      let getToken = token.slice(1, -1);
  
      this.searchEpisodeApiCallId = requestMessage?.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify({
            "Content-Type": configJSON.validationApiContentType,
            token: getToken
        })
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiGetMethod
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.listPodCastsEndPoint}/${data?.id}`
      );
      this.setState({isEpisodeLoading: true});
      runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleSearchEpisodesListResponse = async(from: string, message: Message) => {
    let apiResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    
    if(apiResponse.episodes.data.length > 0) {
      const extractedData = apiResponse.episodes.data.map((item:any) => {
        const { id, attributes } = item;
        const { cover, name, description, episode_time, added } = attributes;

        return {
          id,
          cover,
          name,
          description,
          episode_time,
          songAdded: added?.song_added,
        };
      });
      this.setState({isEpisodeLoading: false});
      this.setState({episodesData: extractedData})
    }
    else {
      this.setState({isEpisodeLoading: false});
      this.setState({episodesData: []})
    }
  }

  handleEpisodeAdd = async(id: string) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const token = await getStorageData('token')
    
    let getToken = token.slice(1, -1);
    
    const main_playlist_id = await getStorageData('main_playlist_id')
    
    this.addEpisodeApiCallId = requestMessage?.messageId
    
      let formdata = new FormData();
      formdata.append("song_id", id);
      formdata.append("id", main_playlist_id);

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.addSong}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formdata
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify({
            token: getToken
        })
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiPostMethod
    );
    this.setState({isAddEpisodeLoading: true});
    this.setState({addEpisodeId: id})
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleAddEpisodesListResponse = async(from: string, message: Message) => {
    let apiResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if(apiResponse?.message === "Song added successfully") {
      this.setState({isAddEpisodeLoading: false})
      const data = {
        id: this.state.selectedSearchPodcast.podcastId,
        title: this.state.selectedSearchPodcast.name,
        subtitle: this.state.selectedSearchPodcast.description,
        cover_image: this.state.selectedSearchPodcast.cover
      }
      this.handleImageCardClick(data)
    }
    else {
      this.setState({isAddEpisodeLoading: false})
      this.showAlert("Error", "Song Not Added")
    }
  }

  hanldeEpisodeDone = () => {
    this.getMainPlaylist();
    this.setState({openPlaylistModal: false})
  }
  
  handleDeleteSong = async(data: IMainPlaylist) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const token = await getStorageData('token')

    let getToken = token.slice(1, -1);
  
    this.getMainPlaylistDelteSongAPICallId = requestMessage?.messageId

    const mainPlaylistID  = await getStorageData('main_playlist_id')

    let formdata = new FormData();
    formdata.append("song_id", data.id);
    formdata.append("id", mainPlaylistID);

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.removeSong}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
          token: getToken
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteMethodtype
    );
  this.setState({isRemoveSongLoading: true});
  runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleDeleteSongResponse = async(from: string, message: Message) => {
    let deleteResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if(deleteResponse?.message === "Song removed successfully") {
      this.setState({isRemoveSongLoading: false})
      this.getMainPlaylist()
    }
    else {
      this.setState({isRemoveSongLoading: false})
      this.showAlert("Error", "Song Not Removed")
    }
  }

  handleFollowingClick = async(data: IPodcastAttributes) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    const token = await getStorageData('token')

    this.followPodcastCallId = requestMessage.messageId

    let cleanedToken = token.slice(1, -1);

    const body = {
      data: {
        followable_id: data.id,
        followable_type: "BxBlockLogin::Podcast", 
      }
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.createfollow}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
          "Content-Type": configJSON.validationApiContentType,
          token: cleanedToken
      })
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleFollowResponse = async(from: string, message: Message) => {
    let followResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if(followResponse.meta.message === "Successfully Followed") {
      this.getPodcastsList()
    }
  }

  handleUnFollowingClick = async(data: IPodcastAttributes) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    const token = await getStorageData('token')
    
    this.unFollowPodcastCallId = requestMessage.messageId


    let cleanedToken = token.slice(1, -1);

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.unfollow}${data.follow_id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
          "Content-Type": configJSON.validationApiContentType,
          token: cleanedToken
      })
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.deleteMethodtype
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleUnfolowResponse = async(from: string, message: Message) => {
    let unfollowResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if(unfollowResponse.message === "Successfully Unfollowed") {
      this.getPodcastsList()
    }
  }
}
