import React from "react";

import {
    // Customizable Area Start
    Typography,
    IconButton,
    Box,
    styled,
    FormControl, Select, MenuItem
    // Customizable Area End
} from "@mui/material";

// Customizable Area Start
import Slider from "react-slick";
import Footer from "../../../../blocks/navigationmenu/src/Footer.web";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { imageArt } from "../assets"
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
    createTheme,
} from "@mui/material/styles";
import PodcastImage from "../../../../components/src/PodcastImage";
import PodcastFollowPost from "../../../../components/src/PodcastFollowPost";


const theme = createTheme({
    palette: {
        primary: {
            main: "#6200ee",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});
const MIN_WINDOW_HEIGHT = 900
// Customizable Area End

// Customizable Area Start
import SubCategoriesController, {
    Props,
    PodcastData
} from "./SubCategoriesController";

// Customizable Area End
export default class SubCategories extends SubCategoriesController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            <>
                    <div style={webStyles.container}>
                        <Box className="activity-feed-section" sx={{ display: "flex", }} >
                            <IconButton
                                sx={{
                                    width: "40px",
                                    height: "40px",
                                    paddingLeft: "15px",
                                    borderRadius: "8px",
                                    color: "#F5F5F5",
                                    background: "#44403C",
                                    "&:hover": {
                                        background: "#44403C",
                                    },
                                    marginRight: "-30px"
                                }}
                                data-test-id="sponsoredBlockId"
                                onClick={() => this.props.handleCommonPath("Discover")}
                            >
                                <ArrowBackIosIcon />
                            </IconButton>
                            <Box sx={webStyles.containerTitle}>Discover</Box>
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "space-between", marginBottom: "15px" }}>
                            <Box sx={{ display: "flex", justifyContent: "flex-start", gap: "37px" }}>
                                <Box sx={{
                                    width: "107px", height: "107px", borderRadius: "12px", backgroundColor: "#FF0807", border: "1px", display: "flex",
                                    justifyContent: "center", alignItems: "center"
                                }}>
                                    <img src={imageArt} alt="image1" style={{ width: "57px", height: "57px" }} />
                                </Box>
                                <Box sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    flexDirection: "column"
                                }}>
                                    <Typography
                                        style={{
                                            fontWeight: 900,
                                            fontSize: "48px",
                                            color: "#FFFFFF"
                                        }}
                                    >
                                        {this.state.categorieName.toUpperCase()}
                                    </Typography>
                                    <Typography
                                        style={{
                                            fontWeight: 900,
                                            fontSize: "20px",
                                            color: "#FFFFFF"

                                        }}
                                    >Podcast to know about {this.state.categorieName}</Typography>
                                </Box>

                            </Box>

                            <Box sx={{ width: "601px", height: "56px", position: "relative" }}>
                                <Typography style={webStyles.label}>Subcategories</Typography>
                                <FormControl fullWidth variant="outlined"
                                    sx={{
                                        "& .MuiOutlinedInput-root": {
                                            "& fieldset": {
                                                borderColor: "#FFFFFF",
                                            },
                                            "&:hover fieldset": {
                                                borderColor: "#FFFFFF",
                                            },
                                            "&.Mui-focused fieldset": {
                                                borderColor: "#FFFFFF",
                                            },
                                            "& .MuiSelect-select": {
                                                color: "#FFFFFF",
                                            },
                                        },
                                        "& .MuiInputLabel-root": {
                                            color: "transparent",
                                        },
                                        "& .MuiSvgIcon-root": {
                                            color: "#FFFFFF",
                                        },
                                    }}>
                                    
                                    <Select
                                        name="state"
                                        
                                        data-test-id="selected-category"
                                        displayEmpty
                                        defaultValue=""
                                        IconComponent={(props) => (
                                            <KeyboardArrowDownIcon {...props} sx={{ color: "#FFFFFF" }} />
                                        )}
                                        MenuProps={{

                                            PaperProps: {
                                                sx: {
                                                    overflowY: 'auto',
                                                    maxHeight: 245,
                                                    backgroundColor: 'black',
                                                    color: "#FFFFFF",
                                                    
                                                    "& .Mui-selected": {
                                                        backgroundColor: "#FF0807 !important",
                                                    },
                                                    "& .MuiMenuItem-root": {
                                                        color: "#FFFFFF",
                                                        border: "1px solid white",
                                                        borderTopRightRadius: "4px",
                                                        borderTopLeftRadius: "4px",
                                                    },

                                                },
                                            },
                                        }}
                                        sx={{ backgroundColor: 'black', color: "#FFFFFF" }}
                                    >
                                         <MenuItem value="" disabled>
                                            Select a topic
                                        </MenuItem>
                                        {
                                            this.state.statesDropdown && this.state.statesDropdown.map((item, index) => (

                                                <MenuItem value={item} key={index}>{item}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </Box>
                        </Box>

                        <Box sx={{ position: 'relative', height: "430px", width: "100%", paddingTop: "3%" }} data-test-id="sponsored">
                            <Typography style={webStyles.title}>Sponsored</Typography>
                            <StyledSponsoredSlider {...this.getSponsoredSettings()}>
                                {this.state.sponsoredPost.map((item: any) => (
                                    <div key={item.id} style={webStyles.sponsoredImgBody}>
                                        <img style={webStyles.image} src={item.src} alt={item.alt} />
                                        <div style={{ position: "absolute", bottom: "17%", left: "1%" }}>
                                            <Typography style={{ ...webStyles.title }}>
                                                JUST ANOTHER PODCAST
                                            </Typography>
                                            <Typography style={{ ...webStyles.episode }}>
                                                {item.episode.toUpperCase()}
                                            </Typography>
                                            <Box sx={{width: "439px", display: "flex", wordWrap: "break-word"}}>
                                            <Typography style={{fontSize: "14px", color: "white", lineHeight: "26px"
                                             }}>
                                                {item.name}
                                            </Typography>
                                            </Box>
                                            <button style={webStyles.sponsoredButton}>
                                                Listen now
                                            </button>
                                        </div>
                                    </div>
                                ))}
                            </StyledSponsoredSlider>
                        </Box>

                        <Box sx={webStyles.tredingEpisodesBox} data-test-id="trendingPodcast">
                            <Typography style={webStyles.title}>Trending Podcast</Typography>
                            <PodcastFollowPost suggestedPodcasts={this.state.trendingPodcast} handlePodcastId={this.props.handlePodcastId} />
                        </Box>
                        <Box sx={webStyles.tredingEpisodesBox} data-test-id="trendingEpisodes">
                            <Typography style={webStyles.title}>Trending Episodes</Typography>
                            <PodcastImage suggestedPodcasts={this.state.trendingEpisode} handleAddAudio={this.props.handleAddAudio} handleSave={this.handleSave} handleUnsave={this.handleUnSave} />
                        </Box>
                    {
                        this.state.subCatData.map((item: { name: string, id: string, data: Array<PodcastData> }) => {
                            return (
                                <Box sx={webStyles.tredingEpisodesBox} data-test-id="trendingPodcast">
                                    <Typography style={webStyles.title}>{item.name}</Typography>
                                    <PodcastFollowPost suggestedPodcasts={item.data} handlePodcastId={this.props.handlePodcastId} />
                                </Box>
                            )
                        })
                    }
                        <Footer navigation={this.props.navigation} id={"1"} />
                    </div>
                </>

        );
        // Customizable Area End
    }
}

// Customizable Area Start
const webStyles = {
    container: {
        overflow: "hidden",
        padding: "20px 50px",
    } as const,
    

    boxContainer: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "flex-start"
    },
    img: {
        height: "40%",
        width: "40%"
    },
    label: {
        fontFamily: "Nexa-Heavy",
        fontSize: "18px",
        fontWeight: 900,
        marginBottom: "16px",
        color: "white",
    },

    tredingEpisodesBox: {
        position: 'relative',
        height: "401px",
        paddingTop: "40px",
    },
    boxInside: {
        background: "#44403C",
        height: "223px",
        width: "276px",
        borderRadius: "30px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        margin: "8px"
    },
    boxSelected: {
        background: "#FF0807"
    },
    boxContain: {
        background: "#312F2D",
        height: "289px",
        width: "239px",
        borderRadius: "16px",
        margin: "8px"
    },
    containerTitle: {
        fontWeight: 900,
        fontSize: "30px",
        lineHeight: "40px",
        letter: "-0.5%",
        color: "#fff",
        marginBottom: "24px",
        fontFamily: "Nexa-Heavy",
        marginLeft: "50px"
    },
    title: {
        fontWeight: 900,
        fontSize: "30px",
        color: '#fff',
        marginBottom: "10px"
    },
    sponsoredImgBody: {
        width: '100%',
        height: "370px",
        position: 'relative' as const
    },
    image: {
        objectFit: "cover" as const,
        borderRadius: "8px",
        width: '100%',
        height: typeof window !== 'undefined' && window.innerWidth > 1440 ? "397px" : "370px",
        display: "flex",
        margin: "0 auto",
    },
    sponsoredButton: {
        color: "#fff",
        backgroundColor: "#FF0807",
        padding: '10px 16px',
        cursor: 'pointer',
        border: 'none',
        borderRadius: '8px',
        fontWeight: 900,
        fontSize: "14px"
    },
    episode: {
        fontSize: '14px',
        color: "#fff",
        lineHeight: '22px',
        fontWeight: 600
    },

};


const StyledSponsoredSlider = styled(Slider)`
  .slick-dots {
    bottom: 10px;
    z-index: 0; 
  }
  .slick-dots li {
    margin: 0px !important;
  }
  .slick-dots li button:before {
    color: #B2B3B3; 
    opacity: 1;   
    font-size: 10px;
  }
  .slick-dots li.slick-active button:before {
    color: #B2B3B3 !important; 
    font-size: 14px; 
  }
`;
// Customizable Area End
