Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.dashboardGetUrl = "/bx_block_dashboard/candidates";
exports.dashboarContentType = "application/json";
exports.dashboarApiMethodType = "GET";
exports.dashboarApiMethodPostType = "POST";
exports.dashboarApiMethodDeleteType = "DELETE";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.labelTitleText = "dashboard";
exports.totalCandidateslabelTitleText = "Total Candidates";
exports.viewDetailsBtn = "View Details";
exports.candidateslabelTitleText = "Candidates";
exports.baseUrl = ""
exports.labelBodyText = "dashboard Body";
exports.lastPlayed = "bx_block_audiomusic2/audio_players/last_played";
exports.suggestedForYou = "bx_block_categories/trending_views/trending_episode";
exports.saveEpisode = "bx_block_catalogue/saved_episodes";
exports.unSaveEpisode = "bx_block_catalogue/saved_episodes";
exports.continueListening = "bx_block_audiomusic2/audio_players";
exports.userDetails ="account_block/accounts/profile";
exports.podcastData = "bx_block_settings/settings/channel";
exports.deleteEpisode = "bx_block_login/episodes";
exports.podcastData = "bx_block_settings/settings/channel";
exports.buttonText = "Save Changes";
exports.editText = "Edit Content"
exports.generalText = "General Info"
exports.publishExpirationText = "Publish and expiration Dates"
exports.premiumExplicitText = "Premium or Explicit Content"
exports.advertisingText = "Advertising"
exports.coverText = "Cover Photo"
exports.chaptersText = "Chapters"
exports.uploadAudioVideoText = "We only accept MP3 and MP4 files. Please upload a new file"
exports.dragDropAudioText = "Drag & Drop Your audio/video file"
exports.browserFileText = "Browse file"
exports.episodeTitleText = "Episode Title"
exports.episodeShowNotesText = "Episode Show Notes"
exports.episodeTypeText = "Episode Type"
exports.seasonNumberText = "Season Number"
exports.episodeNumberText = "Episode Number"
exports.setepisodeExpirationText = "Set episode expiration date?"
exports.whenEpisodeExpireText = "When do you want your episode to expire?"
exports.publishDateText = "Publish Date and Time?"
exports.whatTypeAdsEpisode = "What type of Ads would you like to show pre and post-roll for this episode"
exports.adsEpisodeText = "Ads for the episode?"
exports.adsCountInPreRollText = "How Many Ads Do You Want in Pre-Roll For This Episode?"
exports.adsCountInPostRollText = "How Many Ads Do You Want in Post-Roll For This Episode?"
exports.acceptPreRollText = "Accepted Pre-Roll Ad Inventory"
exports.acceptPostRollText = "Accepted Post-Roll Ad Inventory"
exports.whichAdsInsertPreRollText = "Which Ads would you like to insert in pre-roll?"
exports.whichAdsInsertPostRollText = "Which Ads would you like to insert in post-roll?"
exports.warningMessagePreRollText = "To unlock this section increase the number of Ads you want for Pre-Roll"
exports.warningMessagePostRollText = "To unlock this section increase the number of Ads you want for Post-Roll"
exports.currentEpisodeCoverText = "Current Episode Cover:"
exports.newEpisodeCoverText = "New Episode Cover?"
exports.dragDropPhotoText = "Drag & Drop Your Photo"
exports.truePodcastText = "The True Podcast"
exports.costWinningText = "COST OF Winning"
exports.dropCaptionText = "If You Do Not Upload a New Podcast Episode Cover Photo, the Current Episode Cover Photo will be the default Episode Cover Photo"
exports.podcastCaptionText = "Podcast Runtime: 58:25"
exports.insertPhotosText = "Do you want to insert any additional photos into your podcast at certain times? (Maximum of 20)"
exports.additionalPhotosText = "Additional photos at certain times:"
exports.addChapterText = "Add Another Chapter"
exports.generalInfoId = "generalInfo"
exports.editPublishExpirationId = "editPublishExp"
exports.premiumExplicitId = "premiumExplicitSection"
exports.advertisingId = "advertisingSection"
exports.coverPhotoId = "coverPhotoSection"
exports.chaptersId = "chaptersSection"
exports.dragDropFileText = "Drag & Drop your file here"
exports.uploadText = "Upload";
exports.addAnotherChapter = "Add Another Chapter";
exports.doyouWantPremiumText = "Do you want this to be a premium episode?";
exports.doesContainExpText = "Does This Episode Contain Explicit Content?";
exports.restricted18Text = "Restricted Episode For 18+?";
exports.viewInvOptText = "View Inventory Options";
// Customizable Area End