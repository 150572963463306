Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.productApiContentType = "application/json";
exports.apiMethodTypeGet = "GET";
exports.productAPiEndPoint = "catalogue/catalogues";
exports.postCommunityEndPoint= "bx_block_communityforum/posts"
exports.getSavedEpisodesEndPoint= "/bx_block_login/podcasts"
exports.getCommunityEndPoint= "bx_block_communityforum/posts?podcast_id="
exports.getEpisodeDataEndPoint = "bx_block_settings/settings/channel";
exports.postLikeEpisodeEndPoint = "bx_block_like/likes";
exports.addToPlayEpisodeEndPoint = "bx_block_playlist/audio_play_lists/add_song"
exports.removePlaylistEndPoint = "bx_block_playlist/audio_play_lists/remove_song"
exports.postUnlikeEpisodeEndPoint = "bx_block_like/likes"
exports.catalogueApiMethodPostType = "POST";
exports.likeApiMethodPostType = "POST";
exports.addToPlayistMethodPostType = "POST";
exports.catalogueApiMethodDeleteType = "DELETE";
exports.removePlaylistMethodDeleteType = "DELETE";
exports.likeApiMethodDeleteType = "DELETE"
exports.followApiMethodPostType = "POST";
exports.unfollowApiMethodPostType = "DELETE";
exports.followPodcastEndPoint = "bx_block_catalogue/followers";
exports.unfollowPodcastEndPoint = "bx_block_catalogue/followers";
exports.catalogueSaveEpisode = "bx_block_catalogue/saved_episodes"
exports.catalogueUnSaveEpisode = "bx_block_catalogue/saved_episodes";
exports.postContactHost= "bx_block_contact_us/messages";
exports.exampleAPiMethod="POST";
exports.postMethod = "POST";
exports.aboutTitle = "About"
exports.channelInfoTitle = "The True Podcast"
exports.communityTitle = "The True Podcast"
exports.channelInfodescription = "COST OF WINNING"
exports.channelInfoLocation = "London, UK"
exports.otherShows= "Others Shows From This Host"
exports.host= "Main Host :"
exports.hostNameOther= "THE ATLAS OBSCURA"
exports.hostName = "Ana James"
exports.cancelText= "Cancel"
exports.closeText = "Close"
exports.publishText = "Publish"
exports.saveText= "Send"
exports.shareChannelModal = "Share Channel"
exports.channelUrlText = "Channel URL"
exports.embedText = "Embed"
exports.startAt = "Star At"
exports.copyText = "Copy"
exports.deleteMethod = "DELETE"
exports.podcastLink = "https://www.podcasttown.net/episode/26ec9c9c-c49a-4706-"
exports.rateOrReviewText = "Rate Or Review"
exports.rateThisPodcast = "Rate This Podcast"
exports.leaveAReview = "Leave a Review"
exports.randomDate= "07/30/24"
exports.randomDate2= "07/25/24"
exports.daysAgo = "5 days ago"
exports.dropDownEdit = "Edit"
exports.dropDownSave = "Save"
exports.dropDownUnsave = "Unsave"
exports.TurnOffNotification = "Turn off Notification"
exports.TurnOnNotification = "Turn on Notification"
exports.dropDownDelete = "Delete"
exports.blockUserText = "Block User"
exports.hideText = "Hide";
exports.putMethod = "PUT";
exports.getMethod = "GET"
exports.postMethodHost = "POST"
exports.deleteMethodHost = "Delete"
exports.postHostMethod = "POST"
exports.newLogoUploadEndPoint = "bx_block_settings/settings/"
exports.postReviewAPIEndpoint = "bx_block_reviews/podcasts/"
exports.userDetails ="account_block/accounts/profile";
exports.likePostAPIEndPoint = "bx_block_like/likes";
exports.unLikePostAPIEndPoint = "bx_block_like/likes";
exports.getChannelDataEndPoint = "bx_block_settings/settings/channel";
exports.getOtherShowsAPIEndPoint = "bx_block_dashboard/suggested_podcasts/other_shows"
exports.savePostAPIEndPoint = "bx_block_catalogue/saved_episodes";
exports.unSavePostAPIEndPoint = "bx_block_catalogue/saved_episodes";
exports.turnOffNotificationOfMyPostAPIEndPoint = "bx_block_communityforum/posts";
exports.deletePostAPIEndPoint = "bx_block_communityforum/posts";
exports.hidePostAPIEndPoint = "bx_block_communityforum/posts";
exports.blockUserAPIEndPoint = "account_block/block";
exports.pinPostAPIEndPoint = "bx_block_communityforum/posts";
exports.getAllReviewsEndPoint = "bx_block_reviews/podcasts"
exports.likeReviewAPIEndPoint = "bx_block_like/likes";
exports.unLikeReviewAPIEndPoint = "bx_block_like/likes";
exports.followOtherPodcastAPIEndPoint = "bx_block_catalogue/followers"
exports.unfollowOtherPodcastAPIEndPoint = "bx_block_catalogue/followers"
exports.postComments = "bx_block_comments/comments";
exports.getPostComments = "bx_block_comments/comments?commentable_type=BxBlockCommunityforum::Post&commentable_id=";
exports.getNestedComments = "bx_block_comments/comments?commentable_type=BxBlockComments::Comment&commentable_id=";
exports.addLikeOnComment = "bx_block_like/likes";
exports.removeLike = "bx_block_like/likes/"
// Customizable Area End