export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const back = require("../assets/back.png");
export const audioIcon = require("../assets/audio.png");
export const audioBg = require("../assets/audiobg.png");
export const sound = require("../assets/sound.png");
export const picture = require("../assets/picture.png");
export const trackFull = require("../assets/track.png");
export const pause = require("../assets/pause.png");
export const logo = require("../assets/logo.svg").default
export const podcastImage = require("../assets/podcastImage.png").default
export const ad1 = require("../assets/ads1.png").default
export const ad2 = require("../assets/ads2.png").default
export const ad3 = require("../assets/ads3.png").default
export const cover = require("../assets/cover.png").default
export const heart = require("../assets/heart.svg").default
export const addBtn = require("../assets/addBtn.svg").default
export const backsec = require("../assets/backsec.svg").default
export const nextsec = require("../assets/nextsec.svg").default
export const volumeIcon = require("../assets/volume.svg").default
export const size = require("../assets/size.svg").default
export const next = require("../assets/next.svg").default
export const prev = require("../assets/prev.svg").default
export const comments = require("../assets/comments.svg").default
export const shares = require("../assets/shares.svg").default
export const like = require("../assets/like.svg").default
export const pauseIcon = require("../assets/pauseIcon.svg").default
export const playBtn = require("../assets/playBtn.svg").default
export const activePlaylist = require("../assets/activePlaylist.svg").default
export const podcastImage1 = require("../assets/podcast1.jpg").default
export const podcastImage2 = require("../assets/podcast2.jpg").default
export const carouselNext = require("../assets/carouselNext.svg").default
export const carouselPrev = require("../assets/carouselPrev.svg").default
export const headPhone = require("../assets/headPhone.svg").default
export const suggetedImg = require("../assets/suggested3.jpg").default
export const likedBtn = require("../assets/likedBtn.svg").default
export const addedToPlayList = require("../assets/addedToPlayList.svg").default
export const unfold = require("../assets/unfold.svg").default
export const muteIcon = require("../assets/mute.svg").default
