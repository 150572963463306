import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
export interface EditCategories{
  title : string;
  id : string ; 
  children : React.ReactNode
}
interface Chapter {
  newChapterImage: object | null | string |undefined ;
  startTime: string;
  endTime: string;
}
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  handleCommonPath: (path:string) => void;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
    name : string
    accordionIds: string[],
    data: {
      episodeTitle: string,
      episodeShowNotes: string,
      episodeType: string,
      seasonNumber : number,
      episodeNumber: number,
      setEpisodeExpiryDate: boolean,
      episodeExpiryDay: string,
      episodeExpiryMonth: string,
      episodeExpiryYear: string,
      episodePublishDay: string,
      episodePublishMonth: string,
      episodePublishYear: string,
      episodePublishHour: string,
      episodePublishMin: string,
      isPremiumEpisode: boolean,
      isEpisodeContainExplicit: boolean,
      isEpisodeAdultOnly: boolean,
      isAdsInEpisode: boolean,
      adsType: string,
      additionalPhotos: boolean;
      chapters: Chapter[];
      invalidChapterMessage: string[];
      additionalImages: string[];
    },

   
  // Customizable Area End
}
interface SS {
  id: any;
}
  
export default class EditEpisodeController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    // Customizable Area End
    
    constructor(props: Props) {
      super(props);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      name : "",
      accordionIds: [],
      data : {
        episodeTitle: "",
        episodeShowNotes: "",
        episodeType: "",
        seasonNumber : 0,
        episodeNumber: 0,
        setEpisodeExpiryDate: false,
        episodeExpiryDay: "",
        episodeExpiryMonth: "",
        episodeExpiryYear: "",
        episodePublishDay: "",
        episodePublishMonth: "",
        episodePublishYear: "",
        episodePublishHour: "",
        episodePublishMin: "",
        isPremiumEpisode: false,
        isEpisodeContainExplicit: false,
        isEpisodeAdultOnly: false,
        isAdsInEpisode: true,
        adsType: "audio",
        additionalPhotos: false,
        chapters: [{ newChapterImage: {}, startTime: "", endTime: "" }],
        invalidChapterMessage: Array(20).fill(""),
        additionalImages: [],
      },
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
   
  }

  // Customizable Area Start
  toggleAccordionId = (currentAccordionId: string) => {
    const currentAccordionIdIndex = this.state.accordionIds.findIndex((accordionId: string) => accordionId === currentAccordionId)
    const accordionIds = [...this.state.accordionIds]
    if (currentAccordionIdIndex === -1) {
      accordionIds.push(currentAccordionId)
    } else {
      accordionIds.splice(currentAccordionIdIndex, 1)
    }
    this.setState({ ...this.state,accordionIds})
  }
  toggleAdditionalPhotos = () => this.setState((prevState) => ({
    data: {
      ...prevState.data,
      additionalPhotos : !this.state.data.additionalPhotos
    }
  }))  
  // Customizable Area End
}
