import React from "react";
// Customizable Area Start
import SearchAllResultController, { Props, PodcastDataObject, configJSON } from "./SearchAllResultController";
import { goBack } from "../../email-account-login/src/assets";
import {
  Box,
  LinearProgress,
  styled,
} from "@mui/material";
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
// Customizable Area End

export default class SearchAllResult extends SearchAllResultController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  render() {
    // Customizable Area Start
    return (
      <>
        <StyledWrapperAllSearchList>
          <Wrapper>
            <Title className="mainTitle" id="sponsoredContent" >
              <img
                src={goBack}
                alt="goBack"
                style={{ marginRight: "20px", cursor: "pointer", color: "white" }}
                data-test-id="navigate"
              />
              {configJSON.allSearchResult}
            </Title>
            <ChipData>
              <div style={{ paddingRight: "3%" }}>{configJSON.filterBy}</div>
              <Stack direction="row" spacing={1} >
                <Chip label={configJSON.channel}
                  className={("filterCategory")}
                  data-test-id="filterCategory"
                />
                <Chip label={configJSON.episode}
                  data-test-id="filterEpisode"
                  className={("filterCategory")}
                />
                <Chip label={configJSON.post}
                  className={("filterCategory")}
                  data-test-id="filterPosts"
                />
              </Stack>

            </ChipData>
          </Wrapper>

          <ListParent >
            <ListTitle>
              <ChannelTitle >{configJSON.channel}</ChannelTitle>
            </ListTitle>
            <ListData >
              {this.state.loader ? <Box sx={{ display: 'flex' }}>
                <CircularProgress />
              </Box> :
                <>  {
                  this.state.searchPodcast && this.state.searchPodcast?.map((item: PodcastDataObject) => {
                    return (
                      <div key={item.id} id="podcasts">
                        <SuggestedImgBody>
                          <ImageCov src={item.attributes?.cover_image} />
                          <LabelButton
                            onClick={() => this.handleConditionFollow(item.attributes.follower, item.id)}
                            style={this.cardLabelColorCondition(item.attributes.follower.following)}
                            data-test-id="followPodcastID">
                            {this.cardLabelCondition(item.attributes.follower.following)}
                          </LabelButton>
                          <Description id="podcasts">
                            <CardTitle
                              style={{ ...webStyles.episode, fontWeight: 900, fontFamily: "Nexa-Heavy" }} id="podcasts">
                              {item.attributes?.name.toUpperCase()}
                            </CardTitle>
                            <CardTitle id="podcasts"
                              style={{ textTransform: "capitalize" }}>
                              {`${item.attributes?.hosts[0]?.first_name} ${item.attributes?.hosts[0]?.last_name}`}
                            </CardTitle>
                          </Description>
                        </SuggestedImgBody>
                      </div>
                    )
                  })
                }
                </>

              }
            </ListData>
          </ListParent>
        </StyledWrapperAllSearchList>

      </>
    )
    // Customizable Area End
  }
}

// Customizable Area Start
const StyledWrapperAllSearchList = styled('div')({
  "& *": {
    boxSizing: "border-box",
  },
  "& .likedCount": {
    fontFamily: "Nexa-Heavy",
    fontSize: "14px",
    color: "red"
  },
  "& .count": {
    fontFamily: "Nexa-Heavy",
    fontSize: "14px"
  },
  "& .filterCategory": {
    background: "white",
    color: "black",
    fontWeight: 900,
    fontSize: "14px",
    fontFamily: "Nexa-Heavy",
    height: "32px",
    width: "91px",
    "&:hover": {
      background: "white",
      color: "black",
      cursor: "pointer"
    }
  },
  "& .filteredCategories": {
    background: "#FF0807",
    color: "white",
    "&:hover": {
      background: "#FF0807",
      color: "white",
    }
  }
})

const webStyles = {
  sponsoredImgBody: {
    height: "370px",
    width: '100%',
    position: 'relative' as const
  },
  sponsoredButton: {
    color: "#ffffff",
    backgroundColor: "#FF0807",
    padding: '10px 16px',
    cursor: 'pointer',
    border: 'none',
    borderRadius: '8px',
    fontWeight: 900,
    fontSize: "14px"
  },
  container: {
    padding: "20px 50px"
  } as const,
  imgBody: {
    position: 'relative' as const,
  },
  episode: {
    color: "#ffffff",
    fontSize: '14px',
    lineHeight: '22px',
    fontWeight: 600
  },
  postBody: {
    width: "100%",
    height: "25%",
    backgroundColor: "#312F2D",
    position: 'relative' as const
  },
  title: {
    fontWeight: 900,
    fontSize: "30px",
    color: '#ffffff',
    marginBottom: "10px"
  },
};

const Description = styled("div")({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  height: "76px",
  backgroundColor: "#44403c",
  borderBottomLeftRadius: "16px",
  borderBottomRightRadius: "16px",
  padding: "16px"
});

const ImageCov = styled("img")({
  objectFit: "cover" as const,
  borderRadius: "8px",
  width: '100%',
  height: "223px",
  display: "flex",
  margin: "0 auto",
});

const SuggestedImgBody = styled("div")({
  width: '227px',
  height: "320px",
  position: 'relative' as const
});

const Wrapper = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center"
});

const Title = styled(Box)({
  color: "white", display: "flex", alignItems: "center", marginTop: "10px", marginBottom: "10px", marginLeft: "3%", fontFamily: "Nexa-Heavy", fontSize: "30px", fontWeight: 900
})

const ChipData = styled(Box)({
  display: "flex", alignItems: "center", color: "white", marginRight: "3%", width: "25%"
})

const ListParent = styled(Box)({
  width: "90%", margin: "auto", marginTop: "3%", height: "379px"
})

const ListTitle = styled(Box)({
  color: "white", marginBottom: "30px", marginLeft: "2%", fontFamily: "Nexa-Heavy", fontSize: "20px", fontWeight: 900
})

const ListData = styled('div')({
  display: 'flex', justifyContent: "space-evenly", flexWrap: 'wrap', gap: '10px'
})

const LabelButton = styled('div')({
  textAlign: "center", position: "absolute", right: "10px", top: "15px", color: "white", height: "31px", borderRadius: "8px", fontFamily: "Nexa-Heavy", fontStyle: "16px", fontWeight: 900, "&:hover": { cursor: 'pointer' }
})

const CardTitle = styled(Box)({
  ...webStyles.episode, fontWeight: 900, fontFamily: "Nexa-Heavy"
})

const ChannelTitle = styled('h2')({
  color: "white", marginBottom: "30px", marginLeft: "2%", fontFamily: "Nexa-Heavy", fontSize: "20px", fontWeight: 900
})
// Customizable Area End
