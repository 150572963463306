// Customizable Area Start
import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";

export interface ApiCallMethodInterface {
  contentType: string,
  method: string,
  endPoint: string,
  body?: {}
}

export interface PodcastDataObject {
  id: string,
  type: string,
  attributes: {
    cover_image: string,
    follower: {
      following: boolean,
      follow_id: number
    },
    name: string,
    hosts: [
      {
        first_name: string,
        last_name: string
      }
    ]
  }
}

interface followingAPIData {
  data: {
    followable_id: string
    followable_type: string
  }
}

interface unFollowingAPIData {
  meta: {
    message: string
  }
}

interface GetSearchPodcastSuccessResponse { podcasts: { data: Array<PodcastDataObject> } }

export const configJSON = require("./config");
// Customizable Area End


export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  handleCommonPath: (path: string) => void;
  // Customizable Area End
}



interface S {
  // Customizable Area Start
  searchPodcast: Array<PodcastDataObject>,
  searchValue: string
  filterCategory: any,
  loader: boolean,
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class SearchAllResultController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  searchChannelDataApiCallId: string = "";
  followPodcastCallId: any = "";
  unFollowPodcastCallId: any = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SearchTextMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      searchPodcast: [],
      searchValue: 'song',
      filterCategory: '',
      loader: true,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start 
    this.getChannelDataApi();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start  
    let apiMessageId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    this.validResponse(apiMessageId, responseJson);
    // Customizable Area End
  }

  // Customizable Area Start
  apiCall = async (data: ApiCallMethodInterface) => {
    const { contentType, method, endPoint, body } = data;
    let token = await getStorageData("token");
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        token: JSON.parse(token),
        'Content-Type': contentType,
      })
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    )
    body && requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  validResponse = (apiMessageId: string, responseJson: GetSearchPodcastSuccessResponse & followingAPIData & unFollowingAPIData) => {
    if (apiMessageId === this.searchChannelDataApiCallId) {
      this.getChannelDataApiSuccessCallback(responseJson)
    }
    if (this.followPodcastCallId === apiMessageId) {
      this.handleResForfollowPodcast()
    }
    if (this.unFollowPodcastCallId === apiMessageId) {
      this.handleResForUnfollowPodcast()
    }
  };

  getChannelDataApi = async () => {
    let searchValue = JSON.parse(await getStorageData('previousAllSearchResult')).searchQuery;
    let url = `${configJSON.searchResults}?query=${searchValue || ""}&per_page=10&page=1`;
    this.searchChannelDataApiCallId = await this.apiCall({
      contentType: configJSON.searchApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: url,
    });
  };

  getChannelDataApiSuccessCallback = (responseJson: GetSearchPodcastSuccessResponse) => {
    let searchPodcast = responseJson.podcasts.data;
    this.setState({ loader: false, searchPodcast });
  };

  cardLabelCondition = (isFollow: boolean) => {
    return isFollow ? configJSON.following : configJSON.follow;
  };

  cardLabelColorCondition = (isFollow: boolean) => {
    return isFollow ? { background: "black", width: "108px" } : { background: "#FF0807", width: "84px" };
  };

  handleConditionFollow = (item: any, id: string) => {
    let isFollow = item.following;
    if (isFollow) {
      this.handleUnFollowPodcast(item.follow_id);
    } else {
      this.handleFollowPodcast(id);
    }
  };

  handleUnFollowPodcast = async (id: number) => {
    this.unFollowPodcastCallId = await this.apiCall({
      contentType: configJSON.searchApiContentType,
      method: configJSON.deleteMethod,
      endPoint: `${configJSON.unFollowPodcast}/${id}`
    });
  };

  handleResForfollowPodcast = async () => {
    this.getChannelDataApi();
  };

  handleFollowPodcast = async (id: string) => {
    const body = {
      data: {
        "followable_type": "BxBlockLogin::Podcast",
        "followable_id": id
      }
    }
    this.followPodcastCallId = await this.apiCall({
      contentType: configJSON.searchApiContentType,
      method: configJSON.postMethod,
      endPoint: configJSON.followPodcast,
      body
    });
  }

  handleResForUnfollowPodcast = async () => {
    this.getChannelDataApi()
  };
  // Customizable Area End
}