import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { logo } from "./assets";
import { getStorageData } from "framework/src/Utilities";
import moment from "moment";
import { createRef } from "react";
import { PostCommentsSuccessResponse } from "./CatalogueController";
// Customizable Area End

export const configJSON = require("./config");

export interface ApiCallMethodInterface {
  contentType: string,
  method: string,
  endPoint: string,
  body?: {}
}

export interface Comment {
  id: string;
  type: string;
  attributes: {
      account_id: number;
      commentable_id: number;
      commentable_type: string;
      comment: string;
      created_at: string;
      updated_at: string;
      commentable: {
          id: number;
          account_id: number;
          name: string;
          description: string | null;
          body: string;
          location: string | null;
          created_at: string;
          updated_at: string;
          pinned: boolean;
          podcast_id: number;
          share_post_count: number;
          post_url: string | null;
      };
      account_details: {
          account_id: number;
          first_name: string;
          last_name: string;
          profile: string;
      };
      tags: string[];
      likes: {
          liked: boolean;
          liked_id: string | null;
          count: number;
      };
      reply_count: number;
  };
}

export interface CommentResponse {
  data: Comment[];
}

export interface NumberStringInterface {
  [key: string] : string
}

export interface FetchCommentsError { errors: Array<{ message: string }> }

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  isShowEditHostLogoModal: boolean
  closeEditHostLogoModal: () => void;
  reRenderTheLogo: () => void
  // Customizable Area End
}

interface S {
  arrayHolder: any;
  token: string;
  // Customizable Area Start
  totalStars: number;
  reviewCount: number;
  fullStars: number;
  hasHalfStar: boolean;
  emptyStars: number;
  totalReviews: number;
  podcastName: string;
  followButtonText: string;
  selectedTab: string;
  openEditLogoModal: boolean;
  selectedFile: File | null;
  selectedPostFile: any;
  imagePreviewUrl: string;
  tempLogoUrl: string;
  logo: File | null;
  anchorEl: null;
  openDropDownForOtherPost: null;
  expandedCardId:  number[];
  hostCommunityPostData: any[];
  hostChannelInfoData: any[];
  hostEpisodesData: any;
  hostCardInfoData: any;
  hostCardInfoColorScheme: any;
  ratingStars: number;
  review: string;
  openRateOrReviewDialog: boolean;
  openShareChannelDialog: boolean;
  userProfileDataInHost: any;
  textareaValue: string,
  openDropdownForMyOrOthersPost: string;
  currPostId: string;
  activeVideo: NumberStringInterface;
  openShareHostPostDialog: boolean;
  singleHostCommunityPostData: any;
  isShowSinglePost: boolean;
  postCommentsData: any[];
  commentId: string;
  isShowNestedComment: boolean;
  nestedCommentId: string;
  nestedCommentsData: any[];
  nestedComment: string;
  commentType: string;
  templateType: string;
  openReviewsScreen: boolean;
  allReviewsData: any;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class HostCatalogueController extends BlockComponent<Props, S, SS> {
  getProductApiCallId: any;
  // Customizable Area Start
  getHostEpisodesDataApiCallId: string = "";
  getHostCommunityDataApiCallId: string = "";
  addNewPodcastLogoAPICallId: string = "";
  postReviewApiCallId: string = "";
  userDetailsInHostApiCallId: string = "";
  communityPostCallId: string = "";
  otherShowsInHostApiCallId: string = "";
  savePostAPICallId: string = "";
  pinPostAPICallId: string = "";
  unSavePostAPICallId: string = "";
  turnOffNotificationOfMyPostAPICallId: string = "";
  deletePostAPICallId: string = "";
  hidePostAPICallId: string = "";
  blockUserAPICallId: string = "";
  unlikeHostPostAPICallId: string = "";
  likeHostPostAPICallId: string = "";
  scrollRef: any = createRef();
  getCommentOnPostApiCallId: string = "";
  getNestedCommentOnPostApiCallId: string = "";
  commentOnPostApiCallId: string = "";
  getAllReviewsInHostApiCallId: string = "";
  likeReviewAPICallId: string = "";
  unLikeReviewAPICallId: string = "";
  videoRefs: any = [];
  removeCommentLikeApiCallId: string = "";
  addCommentLikeApiCallId: string = "";
  otherShowsFollowPodcastApiCallId: string = "";
  otherShowsUnFollowPodcastApiCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      arrayHolder: [],
      token: "",
      // Customizable Area Start
      totalStars: 5,
      reviewCount: 3.5,
      fullStars: 0,
      hasHalfStar: false,
      emptyStars: 0,
      totalReviews: 1.5,
      podcastName: "Cost of Winning",
      followButtonText: "Follow",
      selectedTab: "Episodes",
      openEditLogoModal: false,
      selectedFile: null,
      selectedPostFile: null,
      imagePreviewUrl: "",
      tempLogoUrl: "",
      logo: logo,
      anchorEl: null,
      openDropDownForOtherPost: null,
      expandedCardId:  [],
      hostEpisodesData: [],
      hostCardInfoData: [],
      hostCardInfoColorScheme:[],
      hostCommunityPostData: [],
      ratingStars: 0,
      review: "",
      openRateOrReviewDialog: false,
      openShareChannelDialog: false,
      userProfileDataInHost: [],
      textareaValue: "",
      openDropdownForMyOrOthersPost: "",
      currPostId: "",
      activeVideo: {},
      openShareHostPostDialog: false,
      hostChannelInfoData:  [],
      singleHostCommunityPostData: {},
      isShowSinglePost: false,
      postCommentsData: [],
      commentId: "",
      isShowNestedComment: false,
      nestedCommentId: "",
      nestedCommentsData: [],
      nestedComment: "",
      commentType: "",
      templateType: "",
      openReviewsScreen: false,
      allReviewsData: []
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    // Customizable Area Start
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    this.getHostEpisodesDataApiCall()
    this.getHostCommunityDataApiCall();
    this.getOtherShowsAPICall()
    this.getUserDetails();
    this.calculateStars(this.state.reviewCount);
    setTimeout(() => {
      if (this.scrollRef.current) {
        this.scrollRef.current.scrollTo({ top: 0, behavior: "smooth" });
      }
    }, 0);
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    this.handleGetHostEpisodesDataApiRespomse(from, message);
    this.handleGetHostCommunityDataApiResponse(from, message);
    this.handleOtherShowsRes(from, message);
    this.handleUploadNewLogoApiResponse(from, message);
    this.handleResForRateAndReview(from, message);
    this.handleResForuserSettingsApi(from, message);
    this.handleResForCommunity(from, message);
    this.handleResForSavePost(from, message);
    this.handleResForUnSavePost(from, message);
    this.handleResForTurnOffNotificationOfMyPost(from, message);
    this.handleResForDeletePost(from, message);
    this.handleResForHidePost(from, message);
    this.handleResForBlockUserPost(from, message);
    this.handleResForPinPost(from, message);
    this.handleLikePostRes(from, message);
    this.handleunLikePostRes(from, message);
    this.handleGetAllReviewsRes(from, message);
    this.handleLikeReviewsRes(from, message);
    this.handleUnLikeReviewsRes(from, message);
    this.handleFollowOtherShowsRes(from, message);
    this.handleUnFollowOtherShowsRes(from, message);

    let apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    
    this.validResponse(apiRequestCallId, responseJson);

    // Customizable Area End
  }

  // Customizable Area Start
  calculateStars = (reviewCount: number) => {
    const fullStars = Math.floor(reviewCount);
    const hasHalfStar = reviewCount % 1 !== 0 ? true : false;
    const emptyStars = this.state.totalStars - fullStars - (hasHalfStar ? 1 : 0)
    this.setState({ fullStars, hasHalfStar, emptyStars });
  }
  handleFollowButton = () => {
    if (this.state.followButtonText === "Follow") {
      this.setState({ followButtonText: "Followed" });
    }
    else if (this.state.followButtonText === "Followed") {
      this.setState({ followButtonText: "Follow" });
    }
  }
  handleTabChanges = (tab: string) => {
    const newTab = tab;
    this.setState({ selectedTab: newTab });
    if (tab == "Community" || tab === "Episodes & Community") {
      this.setState({ isShowSinglePost: false });
    }
  };
  openEditLogoModal = () => {
    this.setState({ openEditLogoModal: true, tempLogoUrl: "" })
  };
  handleCloseEditLogoDialog = () => {
    this.setState({ openEditLogoModal: false })
  }
  handleDoneClick = async () => {
    let token = await getStorageData("token");
    let podcastId = await getStorageData("podcast_id");
    let { selectedFile } = this.state

    const header = {
      token: JSON.parse(token),
    };
   
    let formdata = new FormData();
    if(selectedFile) {
      formdata.append("podcast[podcast_logo]", selectedFile);
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addNewPodcastLogoAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.newLogoUploadEndPoint}${podcastId}/update_podcast`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleCancelClick = () => {
    this.setState({ selectedFile: null, imagePreviewUrl: "", tempLogoUrl: "" })
    this.setState({ openEditLogoModal: false });
  }
  handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (file) {
      let imageUrl = URL.createObjectURL(file);
      this.setState({ selectedFile: file, tempLogoUrl: imageUrl });
    };
  };

  handleMenuOpen = (event: any, id: string, accEmail: string) => {
    event.stopPropagation(); 
    this.setState({ anchorEl: event.currentTarget, currPostId: id, openDropDownForOtherPost: event.currentTarget });
    if(this.state.userProfileDataInHost.attributes?.email === accEmail) {
      this.setState({ openDropdownForMyOrOthersPost: "My Post" });
    } else {
      this.setState({ openDropdownForMyOrOthersPost: "Others Post" });
    }
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null,});
  };

  handleMenuCloseForOtherUserInHostChannel = () => {
    this.setState({ openDropDownForOtherPost: null,});
  }

  openMenu(item: any) {
    return Boolean(this.state.anchorEl) && item.id === this.state.currPostId
  }

  openMenuForOthersPost(item: any) {
    return Boolean(this.state.openDropDownForOtherPost) && item.id === this.state.currPostId
  }

  handleCardClick = (cardId: number) => {
    this.setState((prevState) => {
      const isExpanded = prevState.expandedCardId.includes(cardId);
      return {
        expandedCardId: isExpanded
          ? prevState.expandedCardId.filter((id:any) => id !== cardId) 
          : [...prevState.expandedCardId, cardId],
      };
    });
  };
  handleGetHostEpisodesDataApiRespomse = (from: string, message: Message) => {
    if (this.getHostEpisodesDataApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)); 
      if (responseJson?.podcast.data.attributes.episodes) {
        this.setState(
          { hostEpisodesData: responseJson.podcast.data.attributes.episodes},
          () => {
            this.videoRefs = this.state.hostEpisodesData.data.map(() => createRef());
          })
        this.setState({ hostCardInfoData: responseJson.podcast.data.attributes})
        this.setState({ hostCardInfoColorScheme: responseJson.podcast.data.attributes.color_scheme.data})
        this.setState({ templateType: this.state.hostCardInfoColorScheme.attributes?.template_type });
        this.selectTabAccToTemplateType();
      }
    }
  }

  getHostEpisodesDataApiCall = async() => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getHostEpisodesDataApiCallId = requestMessage?.messageId

    const token = await getStorageData('token');

    let cleanedToken = token.slice(1, -1);

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getEpisodeDataEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.validationApiContentType,
        token: cleanedToken
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getPostDays = (dateString:any) => {
    return moment(dateString).fromNow();
  };

  selectTabAccToTemplateType() {
    if(this.state.templateType === "Audio Focused" || this.state.templateType === "Video Focused") {
      this.setState({ selectedTab: "Episodes" });
    } else if(this.state.templateType === "Audio + Community Focused" || this.state.templateType === "Video + Community Focused") {
      this.setState({ selectedTab: "Episodes & Community" });
    }
  }

  handleGetHostCommunityDataApiResponse = (from: string, message: Message) => {
    if (this.getHostCommunityDataApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson?.data) {
        const extractedHostCommunityPostData = responseJson.data.map((post:any) => {
          const account = post.attributes.account.attributes;
          const postData = post.attributes;
          return {
            id: post.id,
            name: `${account.first_name} ${account.last_name}`,
            hostName: postData.name,
            email: account.email,
            savePost: postData.save_post,
            post_notification: postData.post_notification,
            pinned: postData.pinned,
            image: postData.photos === null ? "" : postData.photos[0].photot_link,
            body: postData.body,
            likedPost: postData.liked_post,
            date: postData.created_at,
            postUrl: postData.post_url,
            updatedAt: postData.updated_at,
            avatar: account.profile_picture,
            accountId: postData.account.id,
            likes: postData.liked_post.count,
            comments: postData.comments.data.length,
            shares: postData.share_post_count,
            days: this.getPostDays(postData.created_at),
          }
        })
        this.setState({ hostCommunityPostData: extractedHostCommunityPostData })
        this.state.isShowSinglePost && this.handleGetSingleCommunityPost(this.state.commentId);
      }
    }
  }

  handleOtherShowsRes(from: string, message: Message) {
    if (this.otherShowsInHostApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if(responseJson.data) {
        this.setState({ hostChannelInfoData: responseJson.data })
      }
    }
  } 

  handleUploadNewLogoApiResponse(from: string, message: Message) {
    if (this.addNewPodcastLogoAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if(responseJson.message === "Podcast updated successfully") {
        this.setState({ selectedFile: null, tempLogoUrl: "" })
        this.props.closeEditHostLogoModal();
        this.getHostEpisodesDataApiCall();
        this.props.reRenderTheLogo();
      }
    }
  }

  handleResForRateAndReview(from: string, message: Message) {
    if(this.postReviewApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))){
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if(responseJson.message === "Review successfully submitted") {
        this.setState({ openRateOrReviewDialog: false, review: "", ratingStars: 0 })
        this.getHostEpisodesDataApiCall();
      }
    }
  }

  handleResForuserSettingsApi = async (from: string, message: Message) => {
    if (this.userDetailsInHostApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson.data) {
        this.setState({ userProfileDataInHost: responseJson.data });
      }
    }
  };

  handleResForCommunity = async (from: string, message: Message) => {
    if (this.communityPostCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson.data) {
        this.setState({ selectedPostFile: null, textareaValue: "" })
        this.getHostCommunityDataApiCall();
      }
    }
  };

  handleResForSavePost(from: string, message: Message) {
    if (this.savePostAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson.meta.message === "Successfully Saved") {
        this.handleMenuClose();
        this.getHostCommunityDataApiCall();
        this.handleMenuCloseForOtherUserInHostChannel();
      }
    }
  }

  handleResForUnSavePost(from: string, message: Message) {
    if (this.unSavePostAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson.message === "Successfully Unsaved") {
        this.handleMenuClose();
        this.getHostCommunityDataApiCall();
        this.handleMenuCloseForOtherUserInHostChannel();
      }
    }
  }

  handleResForTurnOffNotificationOfMyPost(from: string, message: Message) {
    if (this.turnOffNotificationOfMyPostAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson.message === "Post notification is turned on" || responseJson.message === "Post notification is turned off") {
        this.handleMenuClose();
        this.getHostCommunityDataApiCall();
        this.handleMenuCloseForOtherUserInHostChannel();
      }
    }
  }

  handleResForDeletePost(from: string, message: Message) {
    if (this.deletePostAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson.message === "Post deleted successfully") {
        this.handleMenuClose();
        this.getHostCommunityDataApiCall();
      }
    }
  }

  handleResForHidePost(from: string, message: Message) {
    if (this.hidePostAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson.message === "Post is hidden") {
        this.handleMenuClose();
        this.getHostCommunityDataApiCall();
        this.handleMenuCloseForOtherUserInHostChannel();
      }
    }
  }

  handleResForBlockUserPost(from: string, message: Message) {
    if (this.blockUserAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson.message === "User has been succesfully blocked") {
        this.handleMenuClose();
        this.getHostCommunityDataApiCall();
        this.handleMenuCloseForOtherUserInHostChannel();
      }
    }
  }

  handleResForPinPost(from: string, message: Message) {
    if (this.pinPostAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson.message === "Post is marked as pinned" || responseJson.message === "Post is marked as unpinned") {
        this.handleMenuClose();
        this.getHostCommunityDataApiCall();
      }
    }
  }

  handleLikePostRes(from: string, message: Message) {
    if (this.likeHostPostAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson.data) {
        this.getHostCommunityDataApiCall();
      }
    }
  }

  handleunLikePostRes(from: string, message: Message) {
    if (this.unlikeHostPostAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson.message === "Successfully destroy") {
        this.getHostCommunityDataApiCall();
      }
    }
  }

  handleGetAllReviewsRes(from: string, message: Message) {
    if (this.getAllReviewsInHostApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson.data) {
        this.setState({ allReviewsData: responseJson.data });
      }
    }
  }

  handleLikeReviewsRes(from: string, message: Message) {
    if (this.likeReviewAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson.data) {
        this.getAllReviews();
      }
    }
  }

  handleUnLikeReviewsRes(from: string, message: Message) {
    if (this.unLikeReviewAPICallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson.message === "Successfully destroy") {
        this.getAllReviews();
      }
    }
  }

  handleFollowOtherShowsRes(from: string, message: Message) {
    if (this.otherShowsFollowPodcastApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson.meta.message === "Successfully Followed") {
        this.getOtherShowsAPICall();
      }
    }
  }

  handleUnFollowOtherShowsRes(from: string, message: Message) {
    if (this.otherShowsUnFollowPodcastApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson.message === "Successfully Unfollowed") {
        this.getOtherShowsAPICall();
      }
    }
  }

  handleTextareaChange = (event:any) => {
    this.setState({ textareaValue: event.target.value });
  };

  handleFileUpload = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      this.setState({
        selectedPostFile: file
      });
    }
  };

  handleRemoveFile = () => {
    this.setState({
      selectedPostFile: null
    });
  };

  openShareHostPost(post: any) {
    return this.state.openShareHostPostDialog && post.id === this.state.currPostId
  }

  handleOpenShareHostPostModal(postId: string) {
    this.setState({ openShareHostPostDialog: true, currPostId: postId });
  }

  handleCloseShareHostPostModal = () => {
    this.setState({ openShareHostPostDialog: false });
  }

  handleCopyHostPostUrl() {
    let copyChannelText = document.getElementById("hostPostUrl");
    if(copyChannelText) {
      navigator.clipboard.writeText(copyChannelText.innerText);
    }
  }

  handleVideoPlayPause(key: number) {
    this.videoRefs.forEach((ref: any, idx: any) => {
      if (idx !== key) {
        ref.current.pause();
      }
    });

    const videoElement = this.videoRefs[key].current;
    let dataObject: any = { };
    dataObject[key.toString()] = key.toString();

    if (videoElement?.paused) {
      videoElement.play();
      this.setState({ activeVideo: {...dataObject} });
    } else {
      videoElement?.pause();
      this.setState({ activeVideo: {} });
    }
  }

  getBackgroundColor() {
    return this.state.hostCardInfoColorScheme.attributes?.bg_color;
  }

  getSecondaryColor() {
    return this.state.hostCardInfoColorScheme.attributes?.secondry_color;
  }

  getTextColor() {
    return this.state.hostCardInfoColorScheme.attributes?.text_color;
  }

  getButtonColor() {
    return this.state.hostCardInfoColorScheme.attributes?.button_color;
  }

  renderBlockOrFlex(post: any) {
    return post.image ? "flex" : "none";
  }

  setPadding1(post: any) {
    return post.image ? "10px 10px 10px 95px" : "0px";
  }

  setPadding2(post: any) {
    return post.image ? "10px" : "0px";
  }

  setMargin1(post: any) {
    return post.image ? "18px" : "2px"
  }

  setMargin2() {
    let calculatedMargin;
    if (this.state.selectedTab === 'Episodes & Community') {
      calculatedMargin = "5px";
    } else {
      calculatedMargin = this.state.isShowSinglePost ? "0px" : "25px";
    }
    return calculatedMargin;
  }

  setMargin3() {
    return this.state.selectedTab === "Episodes & Community" ? "20px" : "0px";
  }

  setBorderRadius1() {
    return (this.state.selectedTab === 'Episodes' || this.state.selectedTab === 'Episodes & Community') ? '9px 9px 0px 0px' : '9px';
  }

  setBorderRadius2() {
    return this.state.selectedTab === 'Community' ? '9px 9px 0px 0px' : '9px'
  }

  setBorderRadius3() {
    return this.state.selectedTab === 'ChannelInfo' ? '9px 9px 0px 0px' : '9px'
  }

  setDisplay1() {
    return (this.state.selectedTab === "Episodes & Community" && this.state.templateType === "Audio + Community Focused") ? "block" : "none";
  }

  setDisplay2() {
    return (this.state.selectedTab === "Episodes & Community" && this.state.templateType === "Video + Community Focused") ? "block" : "none";
  }

  setDisplay3() {
    return this.state.selectedTab === "Community" ? "block" : "none";
  }

  setDisplay4() {
    return this.state.selectedTab === "ChannelInfo" ? "block" : "none";
  }

  setBorder1(idx: number) {
    return idx === 0 ? "3px solid #FF0807" : "none";
  }

  checkHeight1() {
    return (this.state.selectedTab === 'Episodes' || this.state.selectedTab === 'Episodes & Community') ? '53px' : '40px';
  }

  checkHeight2() {
    let calculatedHeight;
    if (this.state.selectedTab === 'Community') {
      calculatedHeight = "53px";
    } else {
      calculatedHeight = "40px";
    }
    return calculatedHeight;
  }

  checkHeight3() {
    return (this.state.selectedTab === 'ChannelInfo' ? '53px' : '40px')
  }

  checkIfDisabled1() {
    if(this.state.textareaValue || this.state.selectedPostFile) {
      return this.getButtonColor();
    } else {
      return "#DADADA"
    }
  }

  getUserDetails = async () => {
    let token = await getStorageData("token");
    const header = {
      token: JSON.parse(token),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.userDetailsInHostApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.userDetails
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async handleSend() { 
    let token = await getStorageData("token",true);
    let prodcastId = await getStorageData("podcast_id");

    let name = this.state.hostCardInfoData.name;

    const header = {
      token: token,
    };

    let formdata = new FormData();
    formdata.append("data[podcast_id]",prodcastId)
    formdata.append("data[body]", this.state.textareaValue);
    formdata.append("data[name]", name);
    if(this.state.selectedPostFile) {
      formdata.append("data[photos][]", this.state.selectedPostFile);
    }

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.communityPostCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postCommunityEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
     formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async handlePinPost(id: string) {
    let token = await getStorageData("token");
    const header = {
      "Content-Type": "application/json",
      token: JSON.parse(token),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.pinPostAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.pinPostAPIEndPoint}/${id}/pin_post`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async handleUnSavePostDropDownInHost(id: number) {
    let token = await getStorageData("token");
    const header = {
      token: JSON.parse(token),
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.unSavePostAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.unSavePostAPIEndPoint}/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async handleSavePostDropDownInHost(id: string) {
    let token = await getStorageData("token");
    const header = {
      "Content-Type": "application/json",
      token: JSON.parse(token),
    };
    const body = {
      data: {
        saveable_type: "BxBlockCommunityforum::Post", 
        saveable_id: Number(id)
      }
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.savePostAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.savePostAPIEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethodHost
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async handleTurnOffNotificationOfMyPost(id: string) {
    let token = await getStorageData("token");
    const headerData = {
      "Content-Type": "application/json",
      token: JSON.parse(token),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.turnOffNotificationOfMyPostAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.turnOffNotificationOfMyPostAPIEndPoint}/${id}/toggle_notification`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async handleDeletePost(id: string) {
    let tokenData = await getStorageData("token");
    const headerData = {
      "Content-Type": "application/json",
      token: JSON.parse(tokenData),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deletePostAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deletePostAPIEndPoint}/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteMethodHost
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async handleHidePost(id: string) {
    let tokenHideData = await getStorageData("token");
    const headerHideData = {
      "Content-Type": "application/json",
      token: JSON.parse(tokenHideData),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.hidePostAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.hidePostAPIEndPoint}/${id}/hide_post`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerHideData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async handleBlockUser(id: string) {
    let token = await getStorageData("token");
    const header = {
      "Content-Type": "application/json",
      token: JSON.parse(token),
    };
    const body = {
      "blocked_id": Number(id)
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.blockUserAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.blockUserAPIEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleLikePost = async (id: string) => {
    let token = await getStorageData("token");
    const headerData = {
      "Content-Type": "application/json",
      token: JSON.parse(token),
    };
    const bodyData = {
      data: {
        likeable_id: Number(id),
        likeable_type: "BxBlockCommunityforum::Post"
    }
  }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.likeHostPostAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.likePostAPIEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(bodyData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async handleUnlikePost(id: number) {
    let token = await getStorageData("token");
    const headerData = {
      "Content-Type": "application/json",
      token: JSON.parse(token),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.unlikeHostPostAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.unLikePostAPIEndPoint}/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async handleFollowPodcast(podcastId: string) {
    let token = await getStorageData("token");
    const header = {
      "Content-Type": "application/json",
      token: JSON.parse(token),
    };
    const body = {
      data: {
        followable_type: "BxBlockLogin::Podcast", 
        followable_id: podcastId
      }
    }
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.otherShowsFollowPodcastApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.followOtherPodcastAPIEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async handleUnfollowPodcast(id: number) {
    let token = await getStorageData("token");
    const header = {
      token: JSON.parse(token),
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.otherShowsUnFollowPodcastApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.unfollowOtherPodcastAPIEndPoint}/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );   
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteMethodHost
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async openAllReviewsScreen() {
    this.setState({ openReviewsScreen: true });
    await this.getAllReviews();
  }

  goBackfromReviewsScreen() {
    this.setState({ openReviewsScreen: false });
  }

  getCurrentImageRender() {
    return this.state.hostCardInfoData.podcast_logo ?? this.state.logo;
  }

  renderPinnedBorder(post: any) {
    return post.pinned ? "5px solid #EC2025" : "none";
  }

  handleOpenRateAndReviewDialog() {
    this.setState({ openRateOrReviewDialog: false });
  }

  handleOpenShareChannelDialog() {
    this.setState({ openShareChannelDialog: true });
  }

  handleCloseRateOrReviewDialog() {
    this.setState({ openRateOrReviewDialog: false, review: "", ratingStars: 0 });
  }

  handleCloseShareChannelDialog() {
    this.setState({ openShareChannelDialog: false });
  }

  getRatingsData() {
    return this.state.hostCardInfoData.ratings?.ratings ?? 0;
  }

  ratingDisbaled() {
    return !this.state.review || this.state.ratingStars === 0;
  }

  handleProvideStars(key: number) {
    if(this.state.ratingStars === key+1) {
      this.setState({ ratingStars: key });
    } else {
      this.setState({ ratingStars: key+1 });
    }
  }

  handleReviewInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ review: event.target.value });
  }

  async getAllReviews() {
    let podcastId = await getStorageData("podcast_id");
    let token = await getStorageData("token", true);

    const headerData = {
      "Content-Type": "application/json",
      token: token,
    };
    
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getAllReviewsInHostApiCallId = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAllReviewsEndPoint}/${podcastId}/podcast_reviews`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async handleLikeReview(id: string) {
    let token = await getStorageData("token");
    const headerData = {
      "Content-Type": "application/json",
      token: JSON.parse(token),
    };
    const bodyData = {
      data: {
        likeable_id: Number(id),
        likeable_type: "BxBlockReviews::PodcastReview"
    }
  }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.likeReviewAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.likeReviewAPIEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(bodyData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async handleUnLikeReview(id: number) {
    let token = await getStorageData("token");
    const header = {
      "Content-Type": "application/json",
      token: JSON.parse(token),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.unLikeReviewAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.unLikeReviewAPIEndPoint}/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async postRateOrReviewData() {
    
    let podcastId = await getStorageData("podcast_id");
    let token = await getStorageData("token", true);

    const headerData = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };

    let bodyData = {
      podcast_review: {
        rating: this.state.ratingStars === 0 ? 1 : this.state.ratingStars,
        description: this.state.review
      }
    }

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.postReviewApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.postReviewAPIEndpoint}${podcastId}/podcast_reviews`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(bodyData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postHostMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleCopyChannelUrl() {
    let copyChannelText = document.getElementById("podcastUrl");
    if(copyChannelText) {
      navigator.clipboard.writeText(copyChannelText.innerText);
    }
  }

  handleCopyEmbedUrl() {
    let copyEmbedText = document.getElementById("embedUrl");
    if(copyEmbedText) {
      navigator.clipboard.writeText(copyEmbedText.innerText);
    }
  }

  async getOtherShowsAPICall() {
    let token = await getStorageData("token");
    let podcastId = await getStorageData("podcast_id");
    const header = {
      token: JSON.parse(token),
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.otherShowsInHostApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getOtherShowsAPIEndPoint}/${podcastId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getHostCommunityDataApiCall = async () => {
    let podcastId = await getStorageData("podcast_id");
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getHostCommunityDataApiCallId = requestMessage?.messageId

    const token = await getStorageData('token');

    let getToken = token.slice(1, -1);

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.postCommunityEndPoint}?podcast_id=${podcastId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.validationApiContentType,
        token: getToken
      })
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  apiCall = async (data: ApiCallMethodInterface) => {
    const { contentType, method, endPoint, body } = data;

    let token = await getStorageData("token");
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        token: JSON.parse(token),
        'Content-Type': contentType,
      })
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    )
    body && requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  validResponse = (apiRequestCallId: string, responseJson: FetchCommentsError & CommentResponse & PostCommentsSuccessResponse & { message: string }) => {
    let errors = responseJson.errors;
    if (errors) {
      if (apiRequestCallId == this.getCommentOnPostApiCallId) {
        this.handleGetCommentOnPostFailerCallBack(responseJson)
      }
    } else {
      if (apiRequestCallId == this.getCommentOnPostApiCallId) {
        this.handleGetCommentOnPostSuccessCallBack(responseJson)
      }
      if (apiRequestCallId == this.commentOnPostApiCallId){
        this.handleCommentOnPostSuccessCallBack()
      }
      if (apiRequestCallId == this.getNestedCommentOnPostApiCallId){
        this.handleGetNestedCommentSuccessCallBack(responseJson)
      }
      if (apiRequestCallId == this.addCommentLikeApiCallId){
        this.handleAddCommentLikeSuccessCallBack(responseJson)
      }
      if (apiRequestCallId == this.removeCommentLikeApiCallId){
        this.handleRemoveCommentLikeSuccessCallBack(responseJson)
      }
    }
  };

  handleGetSingleCommunityPost = (postId: string,) => {
    let index = this.state.hostCommunityPostData.findIndex((item) => item.id == postId);
    let communityPost = this.state.hostCommunityPostData[index];    
    this.setState({ singleHostCommunityPostData: communityPost, commentId: postId })
    this.handleGetCommentOnPost(postId);
  }

  handleGetCommentOnPost = async (postId: string) => {
    this.getCommentOnPostApiCallId = await this.apiCall({
      contentType: configJSON.productApiContentType,
      method: configJSON.apiMethodTypeGet,
      endPoint: `${configJSON.getPostComments}${postId}`,
    });
  };

  handleGetCommentOnPostSuccessCallBack = async (responseJson: any) => {
    let data = responseJson.data.reverse();
    this.setState({ isShowSinglePost: true, postCommentsData: data });
  };

  handleGetCommentOnPostFailerCallBack = async (errorResponse: FetchCommentsError) => {
    this.setState({ isShowSinglePost: true });
  };

  handleCommentOnPost = async (commentType: string, id: string) => {
    let comment = {
      comment: {
        commentable_id: id,
        commentable_type: commentType == "post" ? "BxBlockCommunityforum::Post" : "BxBlockComments::Comment",
        comment: this.state.textareaValue
      }
    };
    this.commentOnPostApiCallId = await this.apiCall({
      contentType: configJSON.productApiContentType,
      method: configJSON.likeApiMethodPostType,
      endPoint: configJSON.postComments,
      body: comment
    });
  }

  handleCommentOnPostSuccessCallBack = async () => {
    this.state.commentType !== "nestedComment" && this.handleGetCommentOnPost(this.state.commentId);
    this.handleGetNestedComment(this.state.nestedCommentId);
    this.setState({ textareaValue: "" });
  };

  handleNestedComments = (commentId: string, comment: any, commentType: string) => {
    if (this.state.nestedCommentId == "" || this.state.nestedCommentId !== commentId) {
      this.setState({ nestedCommentId: commentId, nestedCommentsData: [] });
      this.handleGetNestedComment(commentId);
    }
    if (this.state.nestedCommentId == commentId) {
      this.setState({ nestedCommentId: "", nestedCommentsData: [] });
    }
    if (commentType == "nestedComment") {
      this.setState({ nestedCommentId: commentId, postCommentsData: [comment], commentType });
      this.handleGetNestedComment(commentId);
    }
  };

  handleGetNestedComment = async (id: string) => {
    this.getNestedCommentOnPostApiCallId = await this.apiCall({
      contentType: configJSON.productApiContentType,
      method: configJSON.apiMethodTypeGet,
      endPoint: `${configJSON.getNestedComments}${id}`,
    });
  };

  handleGetNestedCommentSuccessCallBack = async (responseJson: PostCommentsSuccessResponse) => {
    if (responseJson.data) {
      this.setState({ nestedCommentsData: responseJson.data.reverse() });
    };    
  };

  handleBackComment = () => {
    this.setState({ nestedCommentsData: [], commentType: "", nestedCommentId: "" })
    this.handleGetCommentOnPost(this.state.commentId);
  };

    handleLike = (id: string, isLike: boolean,likedId:string) => {
      isLike ? this.handleRemoveCommentLike(likedId) : this.handleAddCommentLike(id)
    }
  
    handleAddCommentLike = async (id: string) => {
      let comment = {
        data: {
          likeable_type: "BxBlockComments::Comment",
          likeable_id: id
        }
      };
      this.addCommentLikeApiCallId = await this.apiCall({
        contentType: configJSON.productApiContentType,
        method: configJSON.likeApiMethodPostType,
        endPoint: configJSON.addLikeOnComment,
        body: comment
      });
    };
  
    handleAddCommentLikeSuccessCallBack = async (responseJson: PostCommentsSuccessResponse) => {
      if (responseJson.data) {
        this.handleGetCommentOnPost(this.state.commentId);
        this.handleGetNestedComment(this.state.nestedCommentId);
      };
    };
  
    handleRemoveCommentLike = async (id: string) => {
      this.removeCommentLikeApiCallId = await this.apiCall({
        contentType: configJSON.productApiContentType,
        method: configJSON.catalogueApiMethodDeleteType,
        endPoint: `${configJSON.removeLike}${id}`
      });
    };
  
    handleRemoveCommentLikeSuccessCallBack = async (responseJson: { message: string }) => {
      if (responseJson.message) {
        this.handleGetCommentOnPost(this.state.commentId);
        this.handleGetNestedComment(this.state.nestedCommentId);
      };
    };
  // Customizable Area End
}
