import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { ActivityInterface } from "./dataInterface";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
// Customizable Area Start
import { getStorageData } from "framework/src/Utilities";
import moment from "moment";
import { createRef } from "react";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  activities: Array<ActivityInterface>;
  fetchedPages: number;
  activityFilter: string;
  token: string;
  accountId: number;
  pickerModalVisibility: boolean;
  currentPage: number;
  currentCommentPage: number;
  currentFollowedPostsPage: number;
  activityFeedData: any;
  allCommentData: any;
  allfollowedPostsData: any;
  allSavedPostData: any;
  showAllDataOfBlock: 'Activity' | 'Comment' | 'Podcast' | 'SavedPosts' |'Allblock';
  showAllActivityFeed: boolean;
  showAllComments: boolean;
  anchorEl: null, 
  allSavedPostAnchorEl: null,
  commentAnchorEl: null, 
  followedPostsAnchorEl: null,
  selectedUser: null, 
  expandedCardId:  number[],
  allSavedPostexpandedCardId:  number[],
  expandedFollowedPostsCardId: number[],
  activeCommenBlockTab: number,
  activeFollowedPostsTab: number,
  activeMonthBlockTab: number,
  totalListeningTime: string;
  mostedListendTime: string;
  averageListeningTime: string;
  totalFollowing: string;
  mostedListendImage: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  navigation: any;
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export default class ActivityFeedController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getActivityApiCallId: string = "";
  getActivityFeedApiCallId: string = "";
  activityTypes: string[] = [
    "BxBlockComments::Comment",
    "BxBlockChat::Chat",
    "BxBlockOrderManagement::Order",
    "BxBlockPosts::Post",
  ];
  itemsPerPage = 4;
  commentItemsPerPage = 2;
  FollowedPostsItemsPerPage = 2;
  scrollRef: any = createRef();
  getIndexFilterDataApiCallId: string = "";
  getCommentsApiCallId: string = "";
  getPostApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.state = {
      // Customizable Area Start
      activities: [],
      fetchedPages: 1,
      activityFilter: "",
      token: "",
      accountId: -1,
      pickerModalVisibility: false,
      currentPage: 0, 
      currentCommentPage: 0,
      currentFollowedPostsPage: 0,
      activityFeedData : [],
      
      allCommentData: [],
      allfollowedPostsData: [],
      allSavedPostData: [
        {
          id: 1,
          name: "Carter",
          description:
          "Lorem ipsum dolor sit amet. Proin eget tortor risus. Nulla quis lorem ut libero malesuada feugiat.",
          shares: "223",
          avatar: "https://example.com/avatar3.jpg",
          date: "1 Day",
          likes: "780",
          comments: "432",
        },
        {
          id: 2,
          avatar: "https://example.com/avatar4.jpg",
          description:
          "Quis autem vel eum iure reprehenderit qnihil molestiae consequatur. Nulla facilisi.",
          name: "Brown",
          likes: "320",
          comments: "120",
          shares: "45",
          date: "3 Hours",
        },
        {
          id: 3,
          name: "nderson",
          avatar: "https://example.com/avatar5.jpg",
          comments: "890",
          description:
          "Curaallis quis ac lectus. Donec sollicitudin molestie malesuada.",
          date: "4 Days",
          likes: "1.1K",
          shares: "542",
        },
        {
          id: 4,
          name: "Wilson",
          avatar: "https://example.com/avatar6.jpg",
          description:
          "Ut enim ad minima veniam, quisoriosam, nisi ut aliquid ex ea commodi consequatur.",
          likes: "3.2K",
          comments: "2.1K",
          shares: "1.4K",
          date: "1 Week",
        },
        {
          id: 5,
          avatar: "https://example.com/avatar7.jpg",
          name: "Martinez",
          description:
            "Elementum sed sit amet dui. Pellentesque in ipsum id orci porta dapibus.",
          date: "2 Days",
          likes: "590",
          comments: "230",
          shares: "109",
        },
        {
          id: 6,
          avatar: "https://example.com/avatar8.jpg",
          name: "Taylor",
          description:
            "Aenean euismod elementum nisis. Vivamus magna justo, lacinia eget consectetur sed.",
          date: "12 Hours",
          likes: "1.8K",
          comments: "990",
          shares: "1.2K",
        },
        {
          id: 7,
          avatar: "https://example.com/avatar9.jpg",
          name: "Harris",
          description:
            "Mauris blandit aliquet elit, ea. Nulla porttitor accumsan tincidunt.",
          date: "5 Minutes",
          likes: "110",
          comments: "45",
          shares: "10",
        },
        {
          id: 8,
          avatar: "https://example.com/avatar10.jpg",
          name: "Lee",
          description:
            "Donec rutrum congue leo eget malesuada. Curabitur aliquet quam id dui posuere blandit.",
          date: "3 Weeks",
          likes: "4.5K",
          comments: "3.3K",
          shares: "2.8K",
        },
      ],
      showAllDataOfBlock: 'Allblock',
      showAllActivityFeed: false,
      showAllComments: false,
      anchorEl: null,
      allSavedPostAnchorEl: null,
      commentAnchorEl: null,
      followedPostsAnchorEl: null,
      selectedUser: null, 
      expandedCardId: [], 
      allSavedPostexpandedCardId: [],
      expandedFollowedPostsCardId: [],
      activeCommenBlockTab: 0,
      activeFollowedPostsTab: 0,
      activeMonthBlockTab: 0,
      totalListeningTime: "",
      mostedListendTime: "",
      averageListeningTime: "",
      totalFollowing: "",
      mostedListendImage: ""
      // Customizable Area End
    };
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getToken();
    this.getActivityFeedApiCall();
    setTimeout(() => {
      if (this.scrollRef.current) {
        this.scrollRef.current.scrollTo({ top: 0, behavior: "smooth" });
      }
    }, 0);
    this.handleIndexFilter(this.state.activeMonthBlockTab);
    this.handleGetComments(configJSON.top_comments);
    this.handleGetPosts(configJSON.top_posts);
  }

  setFilter(value: string) {
    this.setState(
      { activityFilter: value, fetchedPages: 1, activities: [] },
      () => {
        this.getAcitivityData();
      }
    );
  }

  setModalVisibility(value: boolean) {
    this.setState({ pickerModalVisibility: value });
  }

  getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };

  getAcitivityData = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getActivityApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.apiEndPoint +
        `?_page=${this.state.fetchedPages}&_limit=10${
          this.state.activityFilter
            ? "&trackable_type=" + this.state.activityFilter
            : ""
        }`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const errorResponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    this.handleGetActivityFeedApiCallResponse(apiRequestCallId, responseJson)
    this.handleIndexFilterApiCallResponse(apiRequestCallId,responseJson);
    this.handleGetCommentsApiCallResponse(apiRequestCallId, responseJson);
    this.handleGetPostsApiCallResponse(apiRequestCallId, responseJson);
  }

  getCreatedDate = (dateString:any) => {
    return moment(dateString).fromNow();
  };

  handleGetActivityFeedApiCallResponse = (apiRequestCallId: string, responseJson:any) => {
    if (this.getActivityFeedApiCallId === apiRequestCallId) {


      const activityFeedTransformedData = responseJson.data.map((item:any) => {
        const actionBy = item.attributes.action_by.data.attributes;
        const feedable = item.attributes.feedable.data.attributes;
    
        return {
          id: item.id,
          name: `${actionBy.first_name} ${actionBy.last_name}`,
          action: item.attributes.title,
          time: item.attributes.time,
          avatar: actionBy.profile_picture,
          isYou: true, 
          likes: feedable.liked_episode.count,
          comments: feedable.comments.data.length,
          shares: feedable.share_episode_count,
          date: this.getCreatedDate(feedable.created_at),
          postImage: feedable.cover_image
        }
      })
      this.setState({ activityFeedData: activityFeedTransformedData });
    }
  }

  handleCardClick = (cardId: number) => {
    this.setState((prevState) => {
      const isExpanded = prevState.expandedCardId.includes(cardId);
      return {
        expandedCardId: isExpanded
          ? prevState.expandedCardId.filter((id:any) => id !== cardId) 
          : [...prevState.expandedCardId, cardId],
      };
    });
  };

  handleAllSavedCardClick = (savedPostCardId: number) => {
    this.setState((prevState) => {
      const isCardExpanded = prevState.allSavedPostexpandedCardId.includes(savedPostCardId);
      return {
        allSavedPostexpandedCardId: isCardExpanded
          ? prevState.allSavedPostexpandedCardId.filter((id: any) => id !== savedPostCardId)
          : [...prevState.allSavedPostexpandedCardId, savedPostCardId],
      };
    });
  };

  handleSliderNextActivityClick = () => {
    const { currentPage, activityFeedData } = this.state;
    const totalPages = Math.ceil(activityFeedData.length / this.itemsPerPage);
    if (currentPage < totalPages - 1) {
      this.setState({ currentPage: currentPage + 1 });
    }
  };

  handleSliderNextCommetClick = () => {
    const { currentCommentPage, allCommentData } = this.state;
    const totalCommentPages = Math.ceil(allCommentData.length / this.commentItemsPerPage);
    if (currentCommentPage < totalCommentPages - 1) {
      this.setState({ currentCommentPage: currentCommentPage + 1 });
    }
  };

  handleSliderNextFollowedPostsClick = () => {
    const { currentFollowedPostsPage, allfollowedPostsData } = this.state;
    const totalfollowedPostPagess = Math.ceil(allfollowedPostsData.length / this.FollowedPostsItemsPerPage);
    if (currentFollowedPostsPage < totalfollowedPostPagess - 1) {
      this.setState({ currentFollowedPostsPage: currentFollowedPostsPage + 1 });
    }
  };

  handleSliderPreviousActivityClick = () => {
    const { currentPage } = this.state;
    if (currentPage > 0) {
      this.setState({ currentPage: currentPage - 1 });
    }
  };

  handleSliderPreviousCommentClick = () => {
    const { currentCommentPage } = this.state;
    if (currentCommentPage > 0) {
      this.setState({ currentCommentPage: currentCommentPage - 1 });
    }
  };

  handleSliderPreviousFollowedPostsClick = () => {
    const { currentFollowedPostsPage } = this.state;
    if (currentFollowedPostsPage > 0) {
      this.setState({ currentFollowedPostsPage: currentFollowedPostsPage - 1 });
    }
  };

  handleDotClick = (index:any) => {
    this.setState({ currentPage: index });
  };

  handleCommentSliderDotClick = (index:any) => {
    this.setState({ currentCommentPage: index });
  }

  handleFolloewdPostsSliderDotClick = (index:any) => {
    this.setState({ currentFollowedPostsPage: index });
  }

  handleShowAllDataOfBlock = (dataBlockName: 'Activity' | 'Comment' | 'Podcast' | 'Allblock') => {
    this.setState({ showAllDataOfBlock: dataBlockName, expandedCardId: [], expandedFollowedPostsCardId: [], allSavedPostexpandedCardId: [] });
  }

  handleShowMoreActivityClick = () => {
    this.setState({ showAllActivityFeed: !this.state.showAllActivityFeed });
  };

  handleShowMoreCommentClick = () => {
    this.setState({ showAllComments: !this.state.showAllComments });
  };

  handleMenuOpen = (event: any) => {
    event.stopPropagation(); 
    this.setState({ anchorEl: event.currentTarget});
  };
  
  handleAllSavedPostMenuOpen = (event: any) => {
    event.stopPropagation(); 
    this.setState({ allSavedPostAnchorEl: event.currentTarget});
  };

  handleCommentMenuOpen = (event: any) => {
    event.stopPropagation(); 
    this.setState({ commentAnchorEl: event.currentTarget});
  };

  handleFollowedPostsMenuOpen = (event: any) => {
    event.stopPropagation(); 
    this.setState({ followedPostsAnchorEl: event.currentTarget});
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null,});
  };

  handleAllSavedPostMenuClose = () => {
    this.setState({ allSavedPostAnchorEl: null,});
  };

  handleCommentMenuClose = () => {
    this.setState({ commentAnchorEl: null});
  };

  handleFollowedPostsMenuClose = () => {
    this.setState({ followedPostsAnchorEl: null});
  };

  handleOptionClick = () => {
    this.handleMenuClose();
  };

   currentActivityData = () => {
    const startIndex = this.state.currentPage * this.itemsPerPage;
    const activityData = this.state.showAllDataOfBlock==='Activity'
      ? this.state.activityFeedData
      : this.state.activityFeedData.slice(startIndex, startIndex + this.itemsPerPage);
    return activityData;
  }

  currentCommentData = () => {
    const startIndex = this.state.currentCommentPage * this.commentItemsPerPage;
    const commentData = this.state.showAllDataOfBlock==='Comment'
      ? this.state.allCommentData
      : this.state.allCommentData.slice(startIndex, startIndex + this.commentItemsPerPage);
    return commentData;
  }

  currentFollowedPostsData = () => {
    const startPodcastIndex = this.state.currentFollowedPostsPage * this.FollowedPostsItemsPerPage;
    const followedPostsData = this.state.showAllDataOfBlock==='Podcast'
      ? this.state.allfollowedPostsData
      : this.state.allfollowedPostsData.slice(startPodcastIndex, startPodcastIndex + this.FollowedPostsItemsPerPage);
    return followedPostsData;
  }

  handleCommentBlockTabChange = (_event:any ,newValue: any) => {
    this.setState({ activeCommenBlockTab: newValue });
    this.handleGetComment(newValue);
  };

  handleFollowedPostsCardClick = (cardId: number) => {
    this.setState((prevState) => {
      const isExpanded = prevState.expandedFollowedPostsCardId.includes(cardId);
      return {
        expandedFollowedPostsCardId: isExpanded
          ? prevState.expandedFollowedPostsCardId.filter((id:any) => id !== cardId) 
          : [...prevState.expandedFollowedPostsCardId, cardId],
      };
    });
  };

  handleFollowedPostsBlockTabChange = (_event:any,newValue: any) => {
    this.setState({ 
      activeFollowedPostsTab: newValue 
    });
    this.handleGetPost(newValue);
  };

  handleHoursTabChange = (_event:any ,newValue: any) => {
    this.setState({ activeMonthBlockTab: newValue });
    this.handleIndexFilter(newValue);
    const timeFilters = ["1_month", "3_months", "6_months", "12_months"];
    const selectedFilter = timeFilters[newValue]; 
    const apiUrl = `${configJSON.top_comments}${selectedFilter}`; 
    this.handleGetComments(apiUrl)
  };

  handleSavedPostsBtnClick = () => {
    this.setState({ showAllDataOfBlock: 'SavedPosts' });
  };

  getActivityFeedApiCall = async() => {
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.getActivityFeedApiCallId = requestMessage?.messageId
  
      const token = await getStorageData('token')
  
      let cleanedToken = token.slice(1, -1);
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getActivityFeedApiEndPoint
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify({
          "Content-Type": configJSON.validationApiContentType,
          token: cleanedToken
        })
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleIndexFilter = async (index: number) => {
    let filterItem;

    if (index == 0) {
      filterItem = "1";
    }
    if (index == 1) {
      filterItem = "3";
    }
    if (index == 2) {
      filterItem = "6";
    }
    if (index == 3) {
      filterItem = "12";
    };

    const token = await getStorageData('token')

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getIndexFilterDataApiCallId = requestMessage?.messageId;

    let cleanedToken = token.slice(1, -1);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.validationApiContentType,
        token: cleanedToken
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.listening_stats}${filterItem}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleIndexFilterApiCallResponse = (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId == this.getIndexFilterDataApiCallId) {
      let totalFollowing = responseJson.following_podcast_count;
      let totalListeningTime = responseJson.total_time;
      let averageListeningTime = responseJson.per_episode_time;
      let mostedListendTime = responseJson.podcast_listen_time;
      let mostedListendImage = responseJson.most_listened_podcast.data.attributes.cover_image; 
      this.setState({ totalFollowing, totalListeningTime, averageListeningTime,mostedListendTime,mostedListendImage });
    };
  };

  handleGetComment = (index:number) => {  
    if(index == 0){
      const timeFilters = [0,"1_month", "3_months", "6_months", "12_months"];
      const selectedFilter = timeFilters[this.state.activeMonthBlockTab]; 
      const apiUrl = `${configJSON.top_comments}${selectedFilter}`; 
      this.handleGetComments(apiUrl)
    }
    if(index == 1){
      this.handleGetComments(configJSON.recent_comments)
    }
  }

  handleGetComments = async (endpoint:string) => {
  
    const token = await getStorageData('token')

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCommentsApiCallId = requestMessage?.messageId;

    let cleanedToken = token.slice(1, -1);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.validationApiContentType,
        token: cleanedToken
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleGetCommentsApiCallResponse = (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId == this.getCommentsApiCallId) {
      let comment_data = responseJson.data.slice(0, 10);
      let final_data = comment_data.map((item: any) => {
        let attributes = item.attributes;
        let accounts = attributes.account_details;
        return ({
          id: item.id,
          name: `${accounts.first_name} ${accounts.last_name}`,
          avatar: accounts.profile,
          date: attributes.created_at,
          likes: attributes.likes.count,
          comments: attributes.reply_count,
          description: attributes.comment,
          shares: attributes.commentable.share_post_count
        })
      })
      this.setState({allCommentData:final_data})
    };
  };

  handleGetPost = (index:number) => {  
    if(index == 0){
      this.handleGetPosts(configJSON.top_posts);
    }
    if(index == 1){
      this.handleGetPosts(configJSON.recent_posts);
    }
  }

  handleGetPosts = async (endpoint:string) => {
  
    const token = await getStorageData('token')

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPostApiCallId = requestMessage?.messageId;

    let cleanedToken = token.slice(1, -1);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.validationApiContentType,
        token: cleanedToken
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleGetPostsApiCallResponse = (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId == this.getPostApiCallId) {
      let comment_data = responseJson.data.slice(0, 10);   

      let final_data = comment_data.map((item: any) => {
        let attributes = item.attributes;
        let accounts = attributes.account.attributes;
        return ({
          id: item.id,
          name: `${accounts.first_name} ${accounts.last_name}`,
          avatar: accounts.profile_picture,
          date: attributes.created_at,
          likes: attributes.liked_post.count,
          comments: attributes.comments.length,
          description: attributes.body,
          shares: attributes.share_post_count
        })
      })      
      this.setState({allfollowedPostsData:final_data})
    };
  };
  // Customizable Area End
}
